import { I18n } from 'vue-i18n'
import { BusinessUnitContext } from '@/services/context/business-unit'
import { NavigationFailure, Router } from 'vue-router'
import { CultureName, CultureObject } from '@/types/common'
import { I18nBusinessUnitData } from '@/services/business-unit/i18n/types'
import { computed, ref, Ref, watchEffect, WritableComputedRef } from 'vue'
import I18nRepository from '@/services/business-unit/i18n/repository'
import { ComputedRef } from '@vue/reactivity'

export interface I18nFacadeType {
  cultureName: Ref<CultureName | undefined>
  readonly activeCultures: ComputedRef<CultureObject[]>
  readonly i18nBusinessUnitData: Ref<I18nBusinessUnitData | undefined>
  readonly name: Ref<string | undefined>

  navigateTo(
    cultureName: CultureName,
    routeName?: string,
  ): Promise<NavigationFailure | void | undefined>

  switch(cultureName: CultureName): Promise<void>
}

export default class I18nFacade implements I18nFacadeType {
  constructor(
    private i18n: I18n,
    private businessUnitContext: BusinessUnitContext,
    private i18nRepository: I18nRepository,
    private router: Router,
    defaultCultureName: CultureName,
  ) {
    this.cultureName = ref<CultureName | undefined>(defaultCultureName)
    this.#i18nBusinessUnitData = ref<I18nBusinessUnitData>()

    watchEffect(() => {
      if (this.cultureName.value) {
        return this.navigateTo(this.cultureName.value)
      }
    })
  }
  #i18nBusinessUnitData: Ref<I18nBusinessUnitData | undefined>

  public cultureName: Ref<CultureName | undefined> = ref<CultureName | undefined>()

  get activeCultures(): ComputedRef<CultureObject[]> {
    return computed(() => this.businessUnitContext.businessUnit?.value?.activeCultures || [])
  }

  get i18nBusinessUnitData(): ComputedRef<I18nBusinessUnitData | undefined> {
    return computed(() => this.#i18nBusinessUnitData.value)
  }

  get name(): ComputedRef<string | undefined> {
    return computed(() => this.#i18nBusinessUnitData.value?.common.name)
  }

  navigateTo(
    cultureName: CultureName,
    routeName?: string,
  ): Promise<NavigationFailure | void | undefined> {
    return this.router.push({
      name: routeName || this.router.currentRoute.value.name || 'deals-list',
      params: {
        ...this.router.currentRoute.value.params,
        locale: cultureName,
      },
      query: {
        ...this.router.currentRoute.value.query,
      },
    })
  }

  async switch(cultureName: CultureName): Promise<void> {
    const i18nBusinessUnit: I18nBusinessUnitData = await this.i18nRepository.get(cultureName)

    this.i18n.global.setLocaleMessage(
      cultureName,
      i18nBusinessUnit.translations as Record<string, string>,
    )

    if (this.i18n.mode === 'legacy') {
      this.i18n.global.locale = cultureName
    } else {
      ;(this.i18n.global.locale as WritableComputedRef<string>).value = cultureName
    }

    this.cultureName.value = cultureName
    this.#i18nBusinessUnitData.value = i18nBusinessUnit

    document?.querySelector('html')?.setAttribute('lang', cultureName)

    const briqBookingsText = 'Briq Bookings'
    const tagline: string = i18nBusinessUnit.common?.tagline
      ? `${i18nBusinessUnit.common?.tagline} | ${briqBookingsText}`
      : briqBookingsText
    document.title = tagline
  }
}
