import { Contract } from '@/services/client/types'
import { EndpointContracts } from '@/services/config/api/types'

const contractDefaults = {
  acceptableHttpCodes: [200],
  isAuthRequired: false,
  isLanguageDependent: false,
  method: 'POST',
  pathArguments: [],
  queryArguments: [],
  bodyArguments: [],
  argumentKeyMap: {
    locale: 'culture',
    domain: 'businessUnitDomain',
    sessionId: 'sessionId',
  },
} as Contract

const contract = (partial: Partial<Contract> = {}): Contract => ({
  ...contractDefaults,
  ...partial,
})

const contracts: EndpointContracts = {
  GET_BUSINESS_UNIT_BY_DOMAIN: contract({
    bodyArguments: ['domain', 'locale'],
  }),
  GET_TRANSLATIONS_BY_DOMAIN: contract({
    bodyArguments: ['domain', 'locale'],
  }),
  GET_BUSINESS_UNIT_OPENDATES: contract({
    bodyArguments: ['domain', 'locale'],
  }),
  GET_DEAL_EXCERPTS_LIST: contract({
    bodyArguments: ['domain', 'locale', 'requestData'],
  }),
  GET_DEAL_DETAILS: contract({
    bodyArguments: ['domain', 'locale', 'requestData'],
  }),
  GET_UPSELLS: contract({
    bodyArguments: ['domain', 'locale', 'requestData'],
  }),
  GET_UPSELL_PRICE: contract({
    bodyArguments: ['domain', 'locale', 'requestData'],
  }),
  GET_SHOPPINGCART: contract({
    bodyArguments: ['domain', 'locale', 'sessionId', 'requestData'],
  }),
  GET_TIMES: contract({
    bodyArguments: ['domain', 'locale', 'requestData'],
  }),
  POST_VOUCHERS: contract({
    bodyArguments: ['domain', 'requestData'],
  }),
}

Object.freeze(contracts)

export default contracts
