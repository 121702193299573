import type { BusinessUnit } from '@/services/business-unit/settings/types'
import { Key } from '@/types/common'
import { computed, ref, Ref, unref } from 'vue'
import { ComputedRef } from '@vue/reactivity'
import { OpenDatesListType } from '@//services/business-unit/open-dates/types'

export type BusinessUnitContext = {
  readonly isBusinessUnitResolved: ComputedRef<boolean>
  readonly businessUnitKey: ComputedRef<Key | undefined>
  readonly businessUnit: Ref<BusinessUnit | undefined>
  readonly currency: Ref<string | undefined>
  readonly faviconUrl: Ref<string | undefined>
  readonly defaultCulture: Ref<string | undefined>
  readonly companyLogoUrl: Ref<string | undefined>
  readonly funnelPhoneNumberDescription: Ref<string | undefined>
  readonly companyUrl: Ref<string | undefined>
  readonly brandingLogoUrl: Ref<string | undefined>
  readonly openDates: Ref<OpenDatesListType | undefined>
}

export default (): BusinessUnitContext | never => {
  const businessUnit: Ref<BusinessUnit | undefined> = ref<BusinessUnit | undefined>()
  const openDates: Ref<string[] | undefined> = ref<string[] | undefined>()

  const makeSettingMixin = (
    settingName: string,
    propertyName: string,
    defaultValue?: string | undefined,
  ) => {
    return {
      get [propertyName](): Ref<string | undefined> {
        return computed(() => {
          const settings = unref(businessUnit)?.settings
          //console.debug(settingName, settings[settingName])

          return (settings && settings[settingName] && settings[settingName].at(0)) ?? defaultValue
        })
      },
    }
  }

  return {
    get isBusinessUnitResolved(): ComputedRef<boolean> {
      return computed(() => typeof businessUnit.value !== 'undefined')
    },
    get businessUnit(): Ref<BusinessUnit | undefined> {
      return businessUnit
    },
    get openDates(): Ref<string[] | undefined> {
      return openDates
    },
    get businessUnitKey(): ComputedRef<Key | undefined> {
      return computed(() => unref(businessUnit)?.key)
    },
    ...makeSettingMixin('BusinessUnitCurrency', 'currency', 'EUR'),

    ...makeSettingMixin('FaviconUrl', 'faviconUrl', '/images/favicon.ico'),

    ...makeSettingMixin('CompanyLogoUrl', 'companyLogoUrl'),

    ...makeSettingMixin('FunnelPhoneNumberDescription', 'funnelPhoneNumberDescription'),

    ...makeSettingMixin('EmailCompanyUrl', 'companyUrl'),

    ...makeSettingMixin('BrandingLogoUrl', 'brandingLogoUrl'),

    get defaultCulture(): Ref<string | undefined> {
      return computed(() => unref(businessUnit)?.defaultCulture.name)
    },
  } as BusinessUnitContext
}
