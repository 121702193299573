import { NavigationFailure, Router } from 'vue-router'

export default async function (
  router: Router,
  option?: { hasUpsells?: boolean; isError?: boolean },
): Promise<NavigationFailure | void | undefined> {
  await router.isReady()
  if (option?.isError) {
    return router.push({ name: 'default-error' })
  }
  return option?.hasUpsells
    ? router.push({ name: 'booking-upsells' })
    : router.push({ name: 'booking-time-selection' })
}
