import { computed, ToRefs, unref } from 'vue'
import { FilterOptionsDataType, ThemeOptionType } from '@/components/deals-list/types'
import { DealsRequestDataType } from '@/components/deals-list/services/deals/request/types'
import { ComputedRef } from '@vue/reactivity'
import { map } from 'ramda'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  theme: ComputedRef<{ key: string; content: string } | undefined>
  removeTheme: () => void
} {
  const theme = computed(() => {
    const themes = unref(props.dealRequestFilters)?.themes
    const themesInLowerCase = themes ? map((f: string) => f.toLowerCase())(themes) : undefined
    const themeKey =
      themesInLowerCase && themesInLowerCase.length > 0 ? themesInLowerCase[0] : undefined

    return unref(props.filterOptionsData).theme.find(
      (item: ThemeOptionType) => item.key === themeKey,
    )
  })

  function removeTheme() {
    const newRequest = {
      ...unref(props.dealRequestFilters),
      themes: [],
    }

    emit('update', newRequest as DealsRequestDataType)
  }

  return {
    theme,
    removeTheme,
  }
}
