import { filter, forEach, includes, clone, isEmpty } from 'ramda'
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const checkDateAvailabilityPerDays = (currentDate, weeklyAvailableDays) => {
  const dayName = days[currentDate.getDay()]
  return weeklyAvailableDays !== null ? includes(dayName, weeklyAvailableDays) : true
}

const checkDateAvailability = (dealAvailability, loopDate) => {
  const startDate = new Date(dealAvailability.startDate)
  const endDate = new Date(dealAvailability.endDate)
  const currentDate = new Date(loopDate)

  // eslint-disable-next-line unicorn/no-null
  if (!dealAvailability.startDate) dealAvailability.startDate = null

  // eslint-disable-next-line unicorn/no-null
  if (!dealAvailability.endDate) dealAvailability.endDate = null

  if (dealAvailability.startDate !== null && dealAvailability.endDate !== null) {
    return currentDate >= startDate && currentDate <= endDate
      ? checkDateAvailabilityPerDays(currentDate, dealAvailability.weeklyAvailableDays)
      : false
  }

  if (dealAvailability.startDate === null && dealAvailability.endDate !== null) {
    return currentDate <= endDate
      ? checkDateAvailabilityPerDays(currentDate, dealAvailability.weeklyAvailableDays)
      : false
  }

  if (dealAvailability.startDate !== null && dealAvailability.endDate === null) {
    return currentDate >= startDate
      ? checkDateAvailabilityPerDays(currentDate, dealAvailability.weeklyAvailableDays)
      : false
  }

  if (dealAvailability.startDate === null && dealAvailability.endDate === null) {
    return checkDateAvailabilityPerDays(currentDate, dealAvailability.weeklyAvailableDays)
  }

  return true
}

export const filterDealOpenDates = (dealAvailabilityDates, businessUnitOpenDates) => {
  return filter((d) => {
    let isDateMakesCondition = false
    forEach((a) => {
      if (isEmpty(a.weeklyAvailableDays)) a.weeklyAvailableDays = clone(days)

      if (checkDateAvailability(a, d)) {
        isDateMakesCondition = true
      }
    })(dealAvailabilityDates)
    if (isDateMakesCondition) return d
  })(businessUnitOpenDates)
}
