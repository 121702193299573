import initStorage from '@/services/storage'
import { I18nBusinessUnitData } from '@/services/business-unit/i18n/types'
import getBrowserStorage from '@/services/storage/browser-storage-provider'
import { BrowserStorageEnum } from '@/services/storage/types'

export const STORAGE_PREFIX = 'BUSINESS_UNIT_I18N_REPOSITORY_'

const storage = initStorage<I18nBusinessUnitData>(
  getBrowserStorage(BrowserStorageEnum.SESSION),
  STORAGE_PREFIX,
)

export default storage
