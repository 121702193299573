import { computed, inject, ref, ToRefs, unref, watchEffect, WritableComputedRef } from 'vue'
import { DealsRequestDataType } from '@/components/deals-list/services/deals/request/types'
import { ComputedRef } from '@vue/reactivity'
import { SortingDirection, SortingProperty } from '@/components/deals-list/utils/types/common'
import { useI18n } from 'vue-i18n'
import { Logger } from '@/services/logger'
import { LOGGER } from '@/injection-keys'
import config from '@/services/config'

type DropdownOption = {
  option: string
  title: string
  extra?: string
}

type SortingOptionType = {
  property: SortingProperty
  direction?: SortingDirection
  title?: string
}

export default function (
  props: ToRefs<
    Readonly<{
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  sortingOptions: ComputedRef<DropdownOption[]>
  sortingValue: WritableComputedRef<DropdownOption | undefined>
} {
  // TODO: Technical debt about importing the logger and wrapping it as a ref every time.
  const logger = ref(inject<Logger>(LOGGER) as Logger)
  const { t } = useI18n()
  const sortingOptions = computed<DropdownOption[]>(() => {
    const tempSortingOptions = [
      {
        property: 'relevance',
        title: t('dealsSortByRelevance'),
      },
      {
        property: 'price',
        direction: 'ascending',
        title: t('dealsSortByPriceLowHigh'),
      },
      {
        property: 'price',
        direction: 'descending',
        title: t('dealsSortByPriceHighLow'),
      },
    ]
    return tempSortingOptions.map((value) => {
      return {
        title: value.title,
        option: value.property,
        extra: value.direction,
      }
    })
  })

  const sortingValue = computed<DropdownOption | undefined>({
    get() {
      const sortings = unref(props.dealRequestFilters)?.sortings
      const tempSortingValue =
        sortings && sortings.length > 0
          ? (sortings[0] as SortingOptionType)
          : {
              property: 'relevance',
              title: t('dealsSortByRelevance'),
              direction: undefined,
            }
      return {
        option: tempSortingValue.property,
        extra: tempSortingValue.direction,
      } as DropdownOption
    },
    set(value) {
      const tempSortingValue = []
      if (value && value.option !== 'relevance') {
        tempSortingValue.push({
          property: value.option,
          direction: value.extra,
        })
      }
      if (config?.isTrackingEnabled) {
        watchEffect(() => {
          logger.value?.info(
            JSON.stringify({
              eventName: 'Sorting',
              content: value,
              isTracked: true,
            }),
          )
        })
      }
      const newRequest = {
        ...unref(props.dealRequestFilters),
        sortings: tempSortingValue,
      }

      emit('update', newRequest as DealsRequestDataType)
    },
  })

  return {
    sortingOptions,
    sortingValue,
  }
}
