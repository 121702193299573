import {
  DealsCustomFilter,
  DealsRequestDataType,
  DealsSegment,
  IS_INCLUSIVE_DEFAULT,
  VisitorClassification,
} from '@/components/deals-list/services/deals/request/types'

const iif = (condition: boolean, trueVal: unknown, falseVal: unknown) =>
  condition ? trueVal : falseVal

const iifObject = (
  condition: boolean,
  trueVal: Record<string, unknown>,
  falseVal: Record<string, unknown> = {},
): Record<string, unknown> => iif(condition, trueVal, falseVal) as Record<string, unknown>

export function getPartial({
  customFilters = [],
  themes = [],
  resourceFilters = [],
  visitorClassifications = [],
  sortings = [],
  isInclusive = IS_INCLUSIVE_DEFAULT,
  visitDate = '',
}: DealsRequestDataType = {}): DealsRequestDataType {
  return {
    ...iifObject(
      customFilters.some((customFilter: DealsCustomFilter) => customFilter.values.length > 0),
      {
        customFilters: customFilters.filter(
          (customFilter: DealsCustomFilter) => customFilter.values.length > 0,
        ),
      },
    ),
    ...iifObject(themes.length > 0, { themes }),
    ...iifObject(resourceFilters.length > 0, { resourceFilters }),
    ...iifObject(
      visitorClassifications.some((visitorClassification: VisitorClassification) =>
        visitorClassification.segments.some((segment: DealsSegment) => segment.visitors > 0),
      ),
      {
        visitorClassifications: visitorClassifications
          .map((visitorClassification: VisitorClassification) => ({
            ...visitorClassification,
            segments: visitorClassification.segments.filter(
              (segment: DealsSegment) => segment.visitors > 0,
            ),
          }))
          .filter(
            (visitorClassification: VisitorClassification) =>
              visitorClassification.segments.length > 0,
          ),
      },
    ),
    ...iifObject(isInclusive !== IS_INCLUSIVE_DEFAULT, { isInclusive }),
    ...iifObject(sortings.length > 0, { sortings }),
    ...iifObject(typeof visitDate === 'string' && visitDate.length > 0, {
      visitDate,
    }),
  }
}
