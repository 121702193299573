import {
  UpsellsResponse,
  UpsellsPricesResponse,
} from '@/components/booking-upsells/services/upsells/response/types'
import client from '@/services/client'

import contracts from '@/services/config/api/contracts'

import type { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'
import { DomainContext } from '@/services/context/domain'
import { CultureName } from '@/types/common'
import { UpsellsError } from '@/components/booking-upsells/services/upsells/response/errors'
import {
  UpsellsRequestDataType,
  UpsellsRequestType,
  UpsellPricesRequestDataType,
} from '@/components/booking-upsells/services/upsells/request/types'
import { dateFormat } from '@/services/string-helpers/formatters'

export interface UpsellsRepositoryInterface {
  get(
    slugKey: string,
    request: UpsellsRequestDataType,
    locale: CultureName,
  ): Promise<UpsellsResponse>
}

export default class UpsellsRepository implements UpsellsRepositoryInterface {
  constructor(
    private domainContext: DomainContext,
    private endpointLocatorProvider: EndpointLocatorProvider,
  ) {}

  async get(
    slugKey: string,
    requestData: Partial<UpsellsRequestDataType>,
    locale: CultureName,
  ): Promise<UpsellsResponse> {
    const locator = this.endpointLocatorProvider('GET_UPSELLS', slugKey + '/upsells')
    const request = client<UpsellsResponse, UpsellsRequestType, UpsellsError>(
      locator,
      contracts.GET_UPSELLS,
      UpsellsError,
    )

    const normalizedRequestData = {
      ...requestData,
      ...(requestData.visitDate !== undefined
        ? { visitDate: dateFormat(requestData.visitDate) }
        : {}),
    } as Partial<UpsellsRequestDataType>
    return await request({
      requestData: normalizedRequestData,
      domain: this.domainContext.domain,
      locale,
    } as UpsellsRequestType)
  }

  async getPrices(
    key: string,
    requestData: Partial<UpsellPricesRequestDataType>,
    locale: CultureName,
  ): Promise<UpsellsPricesResponse> {
    const locator = this.endpointLocatorProvider('GET_UPSELL_PRICE', key + '/upsellprice')
    const request = client<UpsellsPricesResponse, UpsellsRequestType, UpsellsError>(
      locator,
      contracts.GET_UPSELL_PRICE,
      UpsellsError,
    )

    const normalizedRequestData = {
      ...requestData,
      ...(requestData.visitDate !== undefined
        ? { visitDate: dateFormat(requestData.visitDate) }
        : {}),
    } as Partial<UpsellsRequestDataType>
    return await request({
      requestData: normalizedRequestData,
      domain: this.domainContext.domain,
      locale,
    } as UpsellsRequestType)
  }
}
