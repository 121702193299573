import { ApiEndpointLocator } from '@/services/config/api/types'
import replacePathArguments from '@/services/client/replace-path-arguments'
import type { ResolveUrlArguments } from '@/services/client/types'

export default ({ schema, host, port, path }: ApiEndpointLocator) =>
  ({ pathArguments, query }: ResolveUrlArguments): string => {
    const baseUrl = path.toLowerCase().startsWith('http') ? '' : `${schema}://${host}:${port}`

    const pathWithArguments = pathArguments ? replacePathArguments(path, pathArguments) : path

    const queryString =
      query && Object.keys(query).length > 0 ? `?${new URLSearchParams(query).toString()}` : ''

    return `${baseUrl}${pathWithArguments}${queryString}`
  }
