import LocaleRoot from '@/locale-root'
import DealsList from '@/components/deals-list/index'
import DealDetails from '@/components/deal-details/index'
import DealBooking from '@/components/deal-booking'
import DefaultError from '@/components/generic/pages/default-error'

export default [
  {
    path: '/',
    component: LocaleRoot,
    name: 'root',
  },
  {
    path: '/:locale',
    component: LocaleRoot,
    name: 'locale-root',
    children: [
      {
        path: '',
        redirect: { name: 'deals-list' },
      },
      {
        path: 'error',
        component: DefaultError,
        name: 'default-error',
      },
      {
        path: 'list/:filter*',
        component: DealsList,
        name: 'deals-list',
      },
      {
        path: 'deal/:slug',
        component: DealDetails,
        name: 'deal-details',
      },
      {
        path: 'deal/view/:slug',
        component: DealDetails,
        name: 'deal-details-view',
      },
      {
        path: 'book/extras',
        component: DealBooking,
        name: 'booking-upsells',
      },
      {
        path: 'book/choose-times',
        component: DealBooking,
        name: 'booking-time-selection',
      },
      {
        path: 'book/finalize',
        component: DealBooking,
        name: 'booking-summary',
      },
      {
        path: 'book/finalize/pay',
        component: DealBooking,
        name: 'booking-pay',
      },
      {
        path: 'book/thanks',
        component: DealBooking,
        name: 'booking-thanks',
      },
    ],
  },
]
