import { computed, ToRefs, unref, WritableComputedRef } from 'vue'
import { DealsRequestDataType } from '@/components/deals-list/services/deals/request/types'
import { FilterOptionsDataType, ResourceFiltersOptionType } from '@/components/deals-list/types'
import { ComputedRef } from '@vue/reactivity'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  resourceFilterOptions: ComputedRef<ResourceFiltersOptionType[]>
  resourceFiltersValue: WritableComputedRef<string[]>
} {
  const resourceFilterOptions = computed(() => {
    return unref(props.filterOptionsData).resourceFilters
  })

  const resourceFiltersValue = computed<string[]>({
    get() {
      return unref(props.dealRequestFilters)?.resourceFilters || []
    },
    set(value) {
      const dealRequestFilters = unref(props.dealRequestFilters)
      const newRequest = {
        ...dealRequestFilters,
        resourceFilters: value,
      }
      emit('update', newRequest as DealsRequestDataType)
    },
  })

  return {
    resourceFilterOptions,
    resourceFiltersValue,
  }
}
