import shoppingCartHook from '@/components/deal-booking/services/shopping-cart/hooks/shopping-cart-hook'
import { ref, Ref } from 'vue'

export default function (): {
  isConceptFlow: boolean
  bookingKey: Ref<string>
} {
  const { shoppingCartRef } = shoppingCartHook()
  const isConcept = ref<boolean>(shoppingCartRef.value.booking?.isConcept || false)
  const isConceptFlowAllowed = ref<boolean>(shoppingCartRef.value.isConceptFlowAllowed || false)
  const isConceptFlow = isConcept.value && isConceptFlowAllowed.value
  const bookingKey = ref<string>(shoppingCartRef.value.booking?.key || '')

  return {
    isConceptFlow,
    bookingKey,
  }
}
