<template>
  <div class="custom-icons">
    <svg id="icon-pencil" viewBox="0 0 32 32">
      <path
        d="M0.032 22.548l-0.032 6.184c0 0.348 0.126 0.698 0.38 0.95 0.254 0.254 0.572 0.38 0.92 0.38l6.152-0.030c0.004 0 0.009 0 0.014 0 0.355 0 0.675-0.145 0.906-0.38l21.248-21.248c0.236-0.241 0.381-0.572 0.381-0.936s-0.145-0.695-0.381-0.936l0 0-6.090-6.152c-0.241-0.235-0.571-0.38-0.935-0.38s-0.694 0.145-0.935 0.38l0-0-4.25 4.28-16.998 16.97c-0.226 0.241-0.368 0.563-0.38 0.918l-0 0.002zM22.612 3.172l4.28 4.28-2.41 2.41-4.28-4.28 2.408-2.41zM2.664 23.118l15.666-15.666 4.28 4.28-15.664 15.636-4.314 0.032 0.032-4.28z"
      ></path>
    </svg>
  </div>
</template>

<script lang="ts" setup></script>

<style scoped></style>
