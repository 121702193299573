import { createWebHashHistory, createRouter } from 'vue-router'
import { createApp, watch, unref } from 'vue'

import { createI18n, I18n } from 'vue-i18n'

import App from './app'
import config from '@/services/config'
import logger, { Logger } from '@/services/logger'
import pretty from '@/services/string-helpers/pretty-stringify'
import createDomainContext, { DomainContext } from '@/services/context/domain'
import createBusinessUnitContext, { BusinessUnitContext } from '@/services/context/business-unit'
import BusinessUnitRepository from '@/services/business-unit/settings/repository'
import I18nRepository from '@/services/business-unit/i18n/repository'
import createEndpointLocatorProvider, {
  EndpointLocatorProvider,
} from '@/services/client/api-endpoint-locator-provider'
import {
  BUSINESS_UNIT_CONTEXT,
  BUSINESS_UNIT_REPOSITORY,
  CONFIG,
  DOMAIN_CONTEXT,
  I18N_REPOSITORY,
  I18N_FACADE,
  LOGGER,
  ROUTER,
  I18N,
  ENDPOINT_LOCATOR_PROVIDER,
} from '@/injection-keys'
import routes from './routes'
import I18nFacade from '@/services/i18n'
import { createGtm } from 'vue-gtm'
import VueGtag from 'vue-gtag'
import '@datadog/browser-rum/bundle/datadog-rum'

import 'bootstrap'
import '@/assets/styles/_styles.scss'

const domainContext: DomainContext = createDomainContext(config.domains)
const businessUnitContext: BusinessUnitContext = createBusinessUnitContext()
const endpointLocatorProvider: EndpointLocatorProvider = createEndpointLocatorProvider(config.api)

const businessUnitRepository = new BusinessUnitRepository(domainContext, endpointLocatorProvider)

const i18nRepository = new I18nRepository(domainContext, endpointLocatorProvider)

const appLogger: Logger = logger.child({
  get url(): string {
    return document?.location?.href
  },
  get domain(): string {
    return domainContext.domain
  },
  get businessUnitKey(): string | undefined {
    return businessUnitContext.businessUnit?.value?.key
  },
})

if (config.mode !== 'production') {
  appLogger.trace(pretty(config))
}

const i18n: I18n = createI18n({
  legacy: false,
  locale: config.defaultFallbackCulture,
  globalInjection: true,
})

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

const app = createApp(App)

const i18nFacade = new I18nFacade(
  i18n,
  businessUnitContext,
  i18nRepository,
  router,
  businessUnitContext.defaultCulture
    ? (businessUnitContext.defaultCulture.value as string)
    : config.defaultFallbackCulture,
)

try {
  if (config.datadog.applicationId !== '' && config.datadog.clientToken !== '') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).DD_RUM.init(config.datadog)
  }
} catch {
  console.log('Failed init for DD_RUM.')
}

app.provide(CONFIG, config)
app.provide(LOGGER, appLogger)
app.provide(BUSINESS_UNIT_CONTEXT, businessUnitContext)
app.provide(DOMAIN_CONTEXT, domainContext)
app.provide(I18N_FACADE, i18nFacade)
app.provide(ROUTER, router)
app.provide(BUSINESS_UNIT_REPOSITORY, businessUnitRepository)
app.provide(I18N_REPOSITORY, i18nRepository)
app.provide(I18N, i18n)
app.provide(ENDPOINT_LOCATOR_PROVIDER, endpointLocatorProvider)

watch(
  () => businessUnitContext.isBusinessUnitResolved.value,
  () => {
    const faviconUrl: string | undefined = unref(businessUnitContext.faviconUrl)
    const favicon: Element | null = document.querySelector('#favicon')
    if (favicon && faviconUrl !== undefined) {
      favicon.setAttribute('href', faviconUrl)
    }
    if (config.isTrackingEnabled) {
      const gtmIds = []
      gtmIds.push(config.gtmConfig.id)
      if (businessUnitContext.businessUnit.value?.tagManagerId) {
        gtmIds.push(businessUnitContext.businessUnit.value?.tagManagerId)
      }
      for (const item of gtmIds) {
        try {
          app.use(
            createGtm({
              ...config.gtmConfig,
              id: item,
              vueRouter: router,
            }),
          )
        } catch (error) {
          return error
        }
      }
    }
  },
)

if (config.isTrackingEnabled) {
  app.use(VueGtag, config.gaConfig, router)
}

app.use(router)
app.use(i18n)
app.mount('#app')
