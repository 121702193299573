import { ManagedApiError } from '@/services/client/error'
import { DealDetailsErrorResponseType } from '@/components/deal-details/services/deal/response/types'

export class DealDetailsError extends ManagedApiError {
  constructor(
    protected readonly httpCode: number | undefined,
    public readonly errorObject: DealDetailsErrorResponseType,
    message?: string | undefined,
  ) {
    super(httpCode, errorObject, message)
  }
}
