<template>
  <svg viewBox="0 0 32 32">
    <path
      fill="#ffb229"
      style="fill: var(--color1, #ffb229)"
      d="M30.423 10.723l-8.759-0.861c-0.577-0.064-1.051-0.443-1.253-0.958l-3.14-7.923c-0.211-0.576-0.755-0.98-1.393-0.98s-1.182 0.404-1.39 0.97l-3.116 7.923c-0.197 0.53-0.675 0.912-1.248 0.968l-8.762 0.862c-1.309 0.131-1.833 1.83-0.855 2.743l6.601 6.033c0.319 0.294 0.518 0.715 0.518 1.181 0 0.128-0.015 0.252-0.043 0.371l-1.982 8.455c-0.3 1.332 1.079 2.429 2.234 1.722l7.302-4.465c0.48-0.291 1.056-0.291 1.533 0l7.302 4.465c1.158 0.707 2.537-0.367 2.234-1.725l-1.958-8.463c-0.026-0.109-0.042-0.234-0.042-0.363 0-0.466 0.199-0.886 0.518-1.178l6.605-6.035c0.951-0.913 0.399-2.612-0.905-2.743z"
    ></path>
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
