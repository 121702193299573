import type { ApiErrorObject } from '@/services/client/types'

export class ApiError extends Error {
  constructor(protected readonly httpCode: number | undefined, message = 'API Error') {
    super(message)
    this.name = this.constructor.name
  }
}
export class UnmanagedApiError extends ApiError {}

export class ManagedApiError extends ApiError {
  constructor(
    protected readonly httpCode: number | undefined,
    public readonly errorObject: ApiErrorObject,
    message?: string | undefined,
  ) {
    const errorMessage = message ?? errorObject.technicalDescription ?? 'API Error'

    super(httpCode, errorMessage)
  }
}
