import { DomainConfig } from '@/services/config/'

export type DomainContext = {
  readonly isCustomDomain: boolean
  readonly domain: string
  readonly hostname: string
}

export class DomainContextError extends Error {}

export default (
  config: DomainConfig,
  hostname: string | undefined = globalThis?.location?.hostname,
): DomainContext | never => {
  if (typeof hostname === 'undefined') {
    throw new DomainContextError('Domain context cannot be resolved')
  }

  const matchingToplevelDomain = config.topLevel.find((toplevelDomain: string) =>
    hostname.endsWith(toplevelDomain),
  )

  const isCustomDomain = typeof matchingToplevelDomain === 'undefined'
  const domain = isCustomDomain
    ? hostname
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      hostname.slice(0, -matchingToplevelDomain!.length)

  return Object.freeze({
    isCustomDomain,
    domain,
    hostname,
  }) as DomainContext
}
