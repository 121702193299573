<template>
  <svg viewBox="0 0 32 32">
    <path
      d="M35.762 12.535l-1.729-2.459c-0.011 0.007-0.021 0.016-0.031 0.023-1.957 1.366-4.656 0.892-6.027-1.057s-0.896-4.637 1.062-6.003c0.011-0.007 0.022-0.014 0.032-0.021l-1.729-2.459c-0.416-0.592-1.237-0.736-1.831-0.321l-6.84 4.772 1.497 2.129-1.623 1.131-1.497-2.128-16.485 11.501c-0.595 0.414-0.74 1.231-0.323 1.824l8.423 11.976c0.416 0.592 1.237 0.736 1.831 0.321l16.484-11.5-1.478-2.102 1.623-1.132 1.478 2.102 6.84-4.772c0.594-0.415 0.739-1.232 0.323-1.824zM19.696 9.832l1.623-1.132 1.943 2.762-1.624 1.132-1.942-2.762zM24.451 16.671l-1.792-2.547 1.623-1.132 1.792 2.547-1.623 1.132z"
    ></path>
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
