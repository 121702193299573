import type { ApiConfig } from '@/services/config/api/types'
import type { DatadogConfig } from '@/services/config/datadog/types'

import ConfigException from '@/exceptions/config-exception'
import type { ApiSchema } from '@/services/client/types'
import { CultureName } from '@/types/common'
import { GaConfigType, GtmConfigType } from '@/services/config/ga-gtm/types'

export type AppMode = 'production' | 'test' | 'development'

export type DomainConfig = {
  topLevel: string[]
}

export type ConfigType = {
  readonly mode: AppMode
  readonly api: ApiConfig
  readonly domains: DomainConfig
  readonly defaultFallbackCulture: CultureName
  readonly defaultFallbackCurrency: string
  readonly gaConfig: GaConfigType
  readonly gtmConfig: GtmConfigType
  readonly isTrackingEnabled: boolean
  readonly datadog: DatadogConfig
  readonly isSlice2Enabled: string
  readonly whiteListDomains: string[]
}

function required(value?: string, configKey = 'UNKNOWN'): string {
  if (typeof value === 'undefined') {
    // let's panic!
    throw new ConfigException(`Missing config entry ${configKey}`)
  }

  return value
}

function optional(value: string | undefined, defaultValue: string): string {
  return value ?? defaultValue
}

export const Config: ConfigType = {
  defaultFallbackCulture: optional(process.env.VUE_APP_DEFAULT_FALLBACK_CULTURE, 'en-US'),
  defaultFallbackCurrency: optional(process.env.VUE_APP_DEFAULT_FALLBACK_CURRENCY, 'USD'),
  mode: optional(process.env.NODE_ENV, 'development') as AppMode,

  domains: {
    topLevel: [...optional(process.env.VUE_APP_TOPLEVEL_DOMAINS, '').split(',')],
  },

  api: {
    schema: optional(process.env.VUE_APP_API_SCHEMA, 'https') as ApiSchema,
    host: required(process.env.VUE_APP_API_HOST, 'process.env.VUE_APP_API_HOST'),
    port: +optional(process.env.VUE_APP_API_PORT, '443'),
    endpoints: {
      GET_BUSINESS_UNIT_BY_DOMAIN: required(
        process.env.VUE_APP_API_GET_BUSINESS_UNIT_BY_DOMAIN,
        'process.env.VUE_APP_API_GET_BUSINESS_UNIT_BY_DOMAIN',
      ),
      GET_TRANSLATIONS_BY_DOMAIN: required(
        process.env.VUE_APP_API_GET_TRANSLATION,
        'process.env.VUE_APP_API_GET_BUSINESS_UNIT_BY_DOMAIN',
      ),
      GET_BUSINESS_UNIT_OPENDATES: required(
        process.env.VUE_APP_API_GET_BUSINESS_UNIT_OPENDATES,
        'process.env.VUE_APP_API_GET_BUSINESS_UNIT_OPENDATES',
      ),
      GET_DEAL_EXCERPTS_LIST: required(
        process.env.VUE_APP_GET_DEAL_EXCERPTS_LIST,
        'process.env.VUE_APP_GET_DEAL_EXCERPTS_LIST',
      ),
      GET_DEAL_DETAILS: required(
        process.env.VUE_APP_GET_DEAL_DETAILS,
        'process.env.VUE_APP_GET_DEAL_DETAILS',
      ),
      GET_UPSELLS: required(process.env.VUE_APP_GET_UPSELLS, 'process.env.VUE_APP_GET_UPSELLS'),
      GET_UPSELL_PRICE: required(
        process.env.VUE_APP_GET_UPSELL_PRICE,
        'process.env.VUE_APP_GET_UPSELL_PRICE',
      ),
      GET_SHOPPINGCART: required(
        process.env.VUE_APP_GET_SHOPPINGCART,
        'process.env.VUE_APP_GET_SHOPPINGCART',
      ),
      GET_TIMES: required(process.env.VUE_APP_GET_TIMES, 'process.env.VUE_APP_GET_TIMES'),
      POST_VOUCHERS: required(
        process.env.VUE_APP_POST_VOUCHERS,
        'process.env.VUE_APP_POST_VOUCHERS',
      ),
    },
  } as ApiConfig,

  gtmConfig: {
    id: required(process.env.VUE_APP_GTM_KEY, 'process.env.VUE_APP_GTM_KEY'),
    defer: false,
    compatibility: false,
    enabled: true,
    debug: true,
    loadScript: true,
    trackOnNextTick: false,
  },
  gaConfig: {
    config: {
      id: required(process.env.VUE_APP_GA_KEY, 'process.env.VUE_APP_GA_KEY'),
    },
  },
  isTrackingEnabled: process.env.VUE_APP_ENABLE_ANALYTICS
    ? JSON.parse(process.env.VUE_APP_ENABLE_ANALYTICS as string)
    : false,
  datadog: {
    applicationId: optional(
      process.env.VUE_APP_DATADOG_APPLICATION_ID,
      'process.env.VUE_APP_DATADOG_APPLICATION_ID',
    ),
    clientToken: optional(
      process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      'process.env.VUE_APP_DATADOG_CLIENT_TOKEN',
    ),
    site: optional(process.env.VUE_APP_DATADOG_SITE, 'process.env.VUE_APP_DATADOG_SITE'),
    sampleRate: process.env.VUE_APP_DATADOG_SAMPLE_RATE
      ? Number.parseInt(process.env.VUE_APP_DATADOG_SAMPLE_RATE)
      : undefined,
    replaySampleRate: process.env.VUE_APP_DATADOG_REPLAY_SAMPLE_RATE
      ? Number.parseInt(process.env.VUE_APP_DATADOG_REPLAY_SAMPLE_RATE)
      : undefined,
    env: optional(
      process.env.VUE_APP_DATADOG_ENVIRONMENT,
      'process.env.VUE_APP_DATADOG_ENVIRONMENT',
    ),
  } as DatadogConfig,
  isSlice2Enabled: optional((window as any).slice2?.slice2Enabled, 'false'),
  whiteListDomains: [...optional((window as any).slice2?.slice2WhiteList, '').split(',')],
}

Object.freeze(Config)
Object.freeze(Config.api)
Object.freeze(Config.api.endpoints)
Object.freeze(Config.gtmConfig)
Object.freeze(Config.gaConfig)
Object.freeze(Config.isTrackingEnabled)
Object.freeze(Config.datadog)
Object.freeze(Config.isSlice2Enabled)
Object.freeze(Config.whiteListDomains)

export default Config
