<template>
  <svg class="readjusted-icons" viewBox="0 0 32 32">
    <path
      d="M0 16c0-0.573 0.213-1.147 0.639-1.584l13.41-13.759c0.853-0.876 2.236-0.876 3.089 0s0.853 2.294 0 3.169l-11.866 12.175 11.866 12.174c0.853 0.875 0.853 2.294 0 3.169s-2.236 0.875-3.089 0l-13.41-13.759c-0.426-0.437-0.639-1.011-0.639-1.584z"
    ></path>
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.readjusted-icons {
  width: rem(8px);
  margin-top: rem(2px);
}
</style>
