import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ba6bec7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.props.rating > 0.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 0,
            class: "icon",
            name: "icon-star"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating > 1.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 1,
            class: "icon",
            name: "icon-star"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating > 2.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 2,
            class: "icon",
            name: "icon-star"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating > 3.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 3,
            class: "icon",
            name: "icon-star"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating > 4.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 4,
            class: "icon",
            name: "icon-star"
          }))
        : _createCommentVNode("", true),
      ($setup.isWholeNumber($setup.props.rating))
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 5,
            class: "icon",
            name: "icon-star-half"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating < 4.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 6,
            class: "icon",
            name: "icon-star-empty"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating < 3.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 7,
            class: "icon",
            name: "icon-star-empty"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating < 2.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 8,
            class: "icon",
            name: "icon-star-empty"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating < 1.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 9,
            class: "icon",
            name: "icon-star-empty"
          }))
        : _createCommentVNode("", true),
      ($setup.props.rating < 0.5)
        ? (_openBlock(), _createBlock($setup["Icon"], {
            key: 10,
            class: "icon",
            name: "icon-star-empty"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}