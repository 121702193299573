import Cookies from 'js-cookie'
import { DealsRequestDataType } from '@/components/deals-list/services/deals/request/types'
import { getPartial } from '@/components/deals-list/services/deals/request/normalize'

export function setCookies(
  dealRequest: DealsRequestDataType | undefined,
  cookieName = 'metadata',
): void {
  const { visitDate, visitorClassifications } = getPartial(dealRequest ?? {})

  const date = visitDate && visitDate.length > 0 ? visitDate.split('-').join('/') : undefined

  const classificationSegmentValues =
    visitorClassifications && visitorClassifications.length > 0
      ? Object.fromEntries(
          visitorClassifications[0].segments.map(({ code, visitors }) => [code, visitors]),
        )
      : undefined

  const filterCookieData = JSON.stringify({
    ...(date ? { date } : {}),
    ...(classificationSegmentValues ? { classificationSegmentValues } : {}),
  })

  Cookies.set(cookieName, filterCookieData, {
    path: '/',
  })
}
