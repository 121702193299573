import {
  ShoppingCartHookType,
  ShoppingCartRequestDataType,
} from '@/components/deal-booking/services/shopping-cart/request/types'
import { computed, reactive, ref } from 'vue'
import { isEmpty } from 'ramda'

export default function (): ShoppingCartHookType {
  // Data properties
  const shoppingCart = reactive({
    booking: {},
    summary: {},
    isConceptFlowAllowed: false,
  } as ShoppingCartRequestDataType)

  const shoppingCartKey = ref(generateSessionKey() as string)

  // Computed properties
  const shoppingCartRef = computed<ShoppingCartRequestDataType>({
    get(): ShoppingCartRequestDataType {
      const shoppingCartFromSession = sessionStorage.getItem('SHOPPING_CART')
      return shoppingCartFromSession !== null && isEmpty(shoppingCart.booking)
        ? JSON.parse(shoppingCartFromSession as string)
        : shoppingCart
    },
    set(requestData: ShoppingCartRequestDataType): void {
      shoppingCart.booking = requestData.booking
      shoppingCart.summary = requestData.summary
      shoppingCart.isConceptFlowAllowed = requestData.isConceptFlowAllowed

      saveShoppingCartInSession(shoppingCart)
    },
  })

  // Computed properties
  const shoppingCartKeyRef = computed<string>({
    get(): string {
      const shoppingCartKeyFromSession = sessionStorage.getItem('SHOPPING_CART_KEY')
      shoppingCartKeyRef.value = shoppingCartKeyFromSession || generateSessionKey()
      return shoppingCartKey.value
    },
    set(requestData: string): void {
      shoppingCartKey.value = requestData

      saveShoppingCartInSessionKey(shoppingCartKey.value)
    },
  })

  const shoppingCartSessionRef = (): ShoppingCartRequestDataType => {
    const shoppingCartFromSession = sessionStorage.getItem('SHOPPING_CART')
    return JSON.parse(shoppingCartFromSession as string)
  }

  // Methods
  const saveShoppingCartInSession = (shoppingCart: ShoppingCartRequestDataType) => {
    sessionStorage.setItem('SHOPPING_CART', JSON.stringify(shoppingCart))
  }

  return {
    shoppingCartRef,
    shoppingCartKeyRef,
    shoppingCartSessionRef,
  }
}

const generateSessionKey = (): string => {
  const timestamp = Math.floor(Date.now() / 1000)
  const randNumber = Math.ceil(Math.random() * 1000)

  return `${timestamp}` + `${randNumber}`
}

const saveShoppingCartInSessionKey = (shoppingCartKey: string): void => {
  sessionStorage.setItem('SHOPPING_CART_KEY', shoppingCartKey)
}
