import { computed, ToRefs, unref } from 'vue'
import {
  DealsRequestDataType,
  DealsSegment,
  VisitorClassification,
} from '@/components/deals-list/services/deals/request/types'
import {
  FilterOptionsDataType,
  VisitorClassificationOptionType,
} from '@/components/deals-list/types'
import { ComputedRef } from '@vue/reactivity'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  visitorClassificationOptions: ComputedRef<VisitorClassificationOptionType[]>
  visitorsClassificationValue: ComputedRef<(classificationCode: string) => DealsSegment[]>
  updateVisitorsClassificationValue: (
    event: { code: string; visitors: number },
    classificationCode: string,
  ) => void
} {
  const visitorClassificationOptions = computed(() => {
    return unref(props.filterOptionsData).visitorClassifications
  })

  const visitorsClassificationValue = computed(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => (classificationCode: string) => {
      const visitorClassifications = unref(props.dealRequestFilters)?.visitorClassifications
      return (
        visitorClassifications?.find((item) => item.code === classificationCode)?.segments || []
      )
    },
  )

  function updateVisitorsClassificationValue(
    $event: { code: string; visitors: number },
    visitorClassificationCode: string,
  ) {
    const dealRequestFiltersRaw = unref(props.dealRequestFilters)
    const newRequest = {
      ...dealRequestFiltersRaw,
      visitorClassifications: [
        ...(dealRequestFiltersRaw?.visitorClassifications || []).filter(
          (item: VisitorClassification) => item.code !== visitorClassificationCode,
        ),
        {
          code: visitorClassificationCode,
          segments: $event,
        },
      ],
    }

    emit('update', newRequest as DealsRequestDataType)
  }

  return {
    visitorClassificationOptions,
    visitorsClassificationValue,
    updateVisitorsClassificationValue,
  }
}
