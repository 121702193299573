import { ManagedApiError } from '@/services/client/error'
import { ShoppingCartErrorResponseType } from '@/components/deal-booking/services/shopping-cart/response/types'

export class ShoppingCartError extends ManagedApiError {
  constructor(
    protected readonly httpCode: number | undefined,
    public readonly errorObject: ShoppingCartErrorResponseType,
    message?: string | undefined,
  ) {
    super(httpCode, errorObject, message)
  }
}
