import { StorageType } from '@/services/storage/index'
import hash from 'object-hash'

type CacheContextType = {
  context: Record<string, unknown>
  propertyKey: string | undefined
  args: unknown[]
}

function getArgumentsCacheKey(context: CacheContextType): string {
  return hash(
    {
      ...context,
    },
    { encoding: 'hex', algorithm: 'md5' },
  )
}

export function cacheSync<EntityType>(
  storage: StorageType<EntityType>,
  context: Record<string, unknown>,
) {
  return function (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ): PropertyDescriptor {
    const originalMethod = descriptor.value

    descriptor.value = function (...args: unknown[]) {
      const key = getArgumentsCacheKey({
        context,
        propertyKey,
        args,
      })

      if (storage.hasItem(key)) {
        return storage.getItem(key) as EntityType
      }

      const result = originalMethod.apply(this, args)

      storage.setItem(key, result)

      return result
    }

    return descriptor
  }
}

export function cacheAsync<EntityType>(
  storage: StorageType<EntityType>,
  context: Record<string, unknown>,
) {
  return function (
    target: unknown,
    propertyKey: string,
    descriptor: PropertyDescriptor,
  ): PropertyDescriptor {
    const originalMethod = descriptor.value

    descriptor.value = async function (...args: unknown[]) {
      const key = getArgumentsCacheKey({
        context,
        propertyKey,
        args,
      })

      if (storage.hasItem(key)) {
        return storage.getItem(key) as EntityType
      }

      const result = await originalMethod.apply(this, args)

      storage.setItem(key, result)

      return result
    }

    return descriptor
  }
}

export default cacheAsync
