<template>
  <svg viewBox="0 0 32 32" class="readjusted-icons">
    <path
      fill="#cb2828"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26802 0 0 6.26801 0 14C0 21.732 6.26802 28 14 28ZM12 21.1586C12 21.6813 12.1934 22.1209 12.5802 22.4773C12.9671 22.8257 13.4321 23 13.9753 23C14.535 23 15.0123 22.8257 15.4074 22.4773C15.8025 22.1288 16 21.6893 16 21.1586C16 20.628 15.8025 20.1885 15.4074 19.84C15.0123 19.4836 14.535 19.3054 13.9753 19.3054C13.4321 19.3054 12.9671 19.4836 12.5802 19.84C12.1934 20.1964 12 20.6359 12 21.1586ZM15.8148 6H12.1975L12.679 17.8679H15.3333L15.8148 6Z"
    />
  </svg>
</template>
