import { computed, ToRefs, unref, WritableComputedRef } from 'vue'
import { FilterOptionsDataType } from '@/components/deals-list/types'
import { DealsRequestDataType } from '@/components/deals-list/services/deals/request/types'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  visitDate: WritableComputedRef<string>
} {
  const visitDate = computed<string>({
    get() {
      return unref(props.dealRequestFilters)?.visitDate || ''
    },
    set(value) {
      const dealRequestFilters = unref(props.dealRequestFilters)
      const newRequest = {
        ...dealRequestFilters,
        visitDate: value,
      }

      emit('update', newRequest as DealsRequestDataType)
    },
  })

  return {
    visitDate,
  }
}
