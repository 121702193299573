export type SerializeType<T> = (value: T) => string
export type UnserializeType<T> = (value: string) => T

export function serialize<T>(value: T): string {
  return JSON.stringify(value)
}

export function unserialize<T>(value: string): T {
  return JSON.parse(value) as T
}
