export default function (
  isoDuration: string,
  { hours, minutes }: { hours: string; minutes: string },
): string | undefined {
  if (isoDuration === 'PT0S') {
    return undefined
  }

  const isoDurationRegex = /PT(?:([\d,.]+)H)?(?:([\d,.]+)M)?(?:([\d,.]+)S)?/
  const matches = isoDuration.match(isoDurationRegex)
  if (matches && matches.length > 0) {
    const hoursText = matches[1] ? `${matches[1]}${hours}` : ''
    const minutesText = matches[2] ? `${matches[2]}${minutes}` : ''
    return hoursText + (hoursText.length > 0 && minutesText.length > 0 ? ' ' : '') + minutesText
  }
  return undefined
}
