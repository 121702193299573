import { Upsell as UpsellFromSCType } from '@/components/deal-booking/services/shopping-cart/request/types'
import {
  Upsell as UpsellFromApiResponse,
  SegmentType,
  VisitorClassificationsType,
} from '@/components/booking-upsells/services/upsells/response/types'
import { propEq, find, map, clone, forEach, isEmpty } from 'ramda'

const totalVisitors = (visitorClassifications: VisitorClassificationsType[]) => {
  let total = 0
  forEach((vc: VisitorClassificationsType) => {
    forEach((s: SegmentType) => {
      total = total + s.visitors
    })(vc.segments)
  })(visitorClassifications)
  return total
}

export const mergeUpsellsOfResponseAndShoppingCart = (
  upsellsFromApi: UpsellFromApiResponse[],
  upsellsFromShoppingCart: UpsellFromSCType[],
): UpsellFromApiResponse[] => {
  return map((u: UpsellFromApiResponse) => {
    const upsellFromSC = find(propEq('key', u.key), upsellsFromShoppingCart) as UpsellFromSCType

    if (u.visitorClassifications && !isEmpty(u.visitorClassifications[0].segments)) {
      u.visitorClassifications[0].segments = map((s: SegmentType) => {
        if (upsellFromSC && upsellFromSC.visitorClassifications) {
          const upsellSegmentFromSC = find(
            propEq('code', s.code),
            upsellFromSC.visitorClassifications[0].segments,
          ) as SegmentType
          if (upsellSegmentFromSC !== undefined) s.visitors = upsellSegmentFromSC.visitors
        }
        return s
      })(u.visitorClassifications[0].segments)
    }

    if (upsellFromSC !== undefined && upsellFromSC.numberOfPersons !== undefined) {
      u.numberOfPersons = upsellFromSC.numberOfPersons
    }
    return u
  })(clone(upsellsFromApi))
}

export const mergeCurrentUpsellsWithResponse = (
  newUpsells: UpsellFromApiResponse[],
  currentUpsells: UpsellFromApiResponse[],
): UpsellFromApiResponse[] => {
  return map((u: UpsellFromApiResponse) => {
    const currentUpsell = find(propEq('key', u.key), currentUpsells) as UpsellFromApiResponse

    if (u.visitorClassifications && !isEmpty(u.visitorClassifications[0].segments)) {
      u.visitorClassifications[0].segments = map((s: SegmentType) => {
        if (currentUpsell && currentUpsell.visitorClassifications) {
          const upsellSegmentFromSC = find(
            propEq('code', s.code),
            currentUpsell.visitorClassifications[0].segments,
          ) as SegmentType
          if (upsellSegmentFromSC !== undefined) s.visitors = upsellSegmentFromSC.visitors
        }
        return s
      })(u.visitorClassifications[0].segments)

      const countTotal = currentUpsell.visitorClassifications
        ? totalVisitors(currentUpsell.visitorClassifications)
        : 0
      if (countTotal > 0) {
        u.price = currentUpsell.price
      }
    }

    if (
      u.numberOfPersons !== undefined &&
      currentUpsell !== undefined &&
      currentUpsell.numberOfPersons !== undefined
    ) {
      u.numberOfPersons = currentUpsell.numberOfPersons

      if (u.numberOfPersons && u.numberOfPersons > 0) {
        u.price = currentUpsell.price
      }
    }
    return u
  })(clone(newUpsells))
}
