import { map, filter, clone, isEmpty } from 'ramda'

interface VisitorClassification {
  readonly code: string
  segments: DealSegment[]
}

interface DealSegment {
  readonly code: string
  readonly visitors: number
}

export const filterOutSegmentsWithoutVisitors = (
  visitorClassification: VisitorClassification[],
): VisitorClassification[] => {
  return map((c: VisitorClassification) => {
    c.segments = filter((s: DealSegment) => s.visitors > 0)(c.segments)
    c.segments = map((s: DealSegment) => {
      return {
        code: s.code,
        visitors: s.visitors,
      }
    })(c.segments)
    return {
      code: c.code,
      segments: c.segments,
    }
  })(clone(visitorClassification))
}

export const findUpsellsWithVisitors = map((u: any) => {
  if (u.numberOfPersons && u.numberOfPersons > 0) {
    return {
      key: u.key,
      name: u.name,
      slug: 'test', // TODO: this is temporary here until .net removes it from the sc model
      numberOfPersons: u.numberOfPersons as number,
    }
  } else if (u.visitorClassifications && !isEmpty(u.visitorClassifications[0].segments)) {
    const segments = filter((s: any) => {
      if (s.visitors > 0) {
        return s
      }
    })(u.visitorClassifications[0].segments)
    const visitorsClassificationsClone = clone(u.visitorClassifications)
    visitorsClassificationsClone[0].segments = segments
    if (!isEmpty(segments))
      return {
        key: u.key,
        name: u.name,
        slug: 'test', // TODO: this is temporary here until .net removes it from the sc model
        visitorClassifications: visitorsClassificationsClone,
      }
  }
})
