import client from '@/services/client'

import contracts from '@/services/config/api/contracts'

import type { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'
import { DomainContext } from '@/services/context/domain'
import { CultureName, SessionId } from '@/types/common'
import { ShoppingCartError } from '@/components/deal-booking/services/shopping-cart/response/errors'
import {
  ShoppingCartRequestDataType,
  ShoppingCartRequestType,
  ShoppingCart,
} from '@/components/deal-booking/services/shopping-cart/request/types'
import { ShoppingCartResponse } from '@/components/deal-booking/services/shopping-cart/response/types'

export interface ShoppingCartRepositoryInterface {
  get(
    request: ShoppingCart,
    locale: CultureName,
    sessionId: SessionId,
  ): Promise<ShoppingCartResponse>
}

export default class ShoppingCartRepository implements ShoppingCartRepositoryInterface {
  constructor(
    private domainContext: DomainContext,
    private endpointLocatorProvider: EndpointLocatorProvider,
  ) {}

  async get(
    requestData: Partial<ShoppingCart>,
    locale: CultureName,
    sessionId: SessionId,
  ): Promise<ShoppingCartResponse> {
    const locator = this.endpointLocatorProvider('GET_SHOPPINGCART')
    const request = client<ShoppingCartResponse, ShoppingCartRequestType, ShoppingCartError>(
      locator,
      contracts.GET_SHOPPINGCART,
      ShoppingCartError,
    )

    const normalizedRequestData = {
      ...requestData,
    } as Partial<ShoppingCartRequestDataType>

    return await request({
      requestData: normalizedRequestData,
      domain: this.domainContext.domain,
      locale,
      sessionId,
    } as ShoppingCartRequestType)
  }
}
