import client from '@/services/client'
import contracts from '@/services/config/api/contracts'
import { DomainContext } from '@/services/context/domain'
import { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'
import {
  TimesRequestDataType,
  TimesRequestType,
} from '@/components/booking-time-selection/services/request/types'
import { TimesResponse } from '@/components/booking-time-selection/services/response/types'
import { TimesError } from '@/components/booking-time-selection/services/response/errors'
import { CultureName } from '@/types/common'

export interface TimesRepositoryInterface {
  get(locale: CultureName, request: TimesRequestDataType): Promise<TimesResponse>
}

export default class TimesRepository implements TimesRepositoryInterface {
  constructor(
    private domainContext: DomainContext,
    private endpointLocatorProvider: EndpointLocatorProvider,
  ) {}

  async get(
    locale: CultureName,
    requestData: Partial<TimesRequestDataType>,
  ): Promise<TimesResponse> {
    const locator = this.endpointLocatorProvider('GET_TIMES')
    const request = client<TimesResponse, TimesRequestType, TimesError>(
      locator,
      contracts.GET_TIMES,
      TimesError,
    )

    return await request({
      domain: this.domainContext.domain,
      locale,
      requestData,
    } as TimesRequestType)
  }
}
