import { computed, ToRefs, unref } from 'vue'
import { FilterOptionsDataType } from '@/components/deals-list/types'
import { DealsRequestDataType } from '@/components/deals-list/services/deals/request/types'
import { ComputedRef } from '@vue/reactivity'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  visitDate: ComputedRef<string>
  removeVisitDate: () => void
} {
  const visitDate = computed<string>(() => {
    return unref(props.dealRequestFilters)?.visitDate || ''
  })

  function removeVisitDate(): void {
    const newRequest = {
      ...unref(props.dealRequestFilters),
      visitDate: '',
    }

    emit('update', newRequest as DealsRequestDataType)
  }

  return {
    visitDate,
    removeVisitDate,
  }
}
