import { ManagedApiError } from '@/services/client/error'
import { BusinessUnitErrorObject } from '@/services/business-unit/settings/types'

export class BusinessUnitApiError extends ManagedApiError {
  constructor(
    protected readonly httpCode: number | undefined,
    public readonly errorObject: BusinessUnitErrorObject,
    message?: string | undefined,
  ) {
    super(httpCode, errorObject, message)
  }
}
