<template>
  <svg viewBox="0 0 30 32" class="resized-icons">
    <path
      d="M13.843 3.743c0.549-2.152 2.501-3.743 4.823-3.743s4.274 1.591 4.823 3.743c0.051-0.006 0.102-0.009 0.154-0.009h4.978c0.687 0 1.244 0.557 1.244 1.244s-0.557 1.244-1.244 1.244h-4.978c-0.052 0-0.104-0.003-0.154-0.009-0.549 2.152-2.501 3.743-4.823 3.743s-4.274-1.591-4.823-3.743c-0.051 0.006-0.102 0.009-0.154 0.009h-12.444c-0.687 0-1.244-0.557-1.244-1.244s0.557-1.244 1.244-1.244h12.444c0.052 0 0.104 0.003 0.154 0.009zM18.667 7.467c1.375 0 2.489-1.114 2.489-2.489s-1.114-2.489-2.489-2.489c-1.375 0-2.489 1.114-2.489 2.489s1.114 2.489 2.489 2.489zM3.888 14.409c0.549-2.152 2.501-3.743 4.823-3.743s4.274 1.591 4.823 3.743c0.051-0.006 0.102-0.009 0.154-0.009h14.933c0.687 0 1.244 0.557 1.244 1.244s-0.557 1.244-1.244 1.244h-14.933c-0.052 0-0.104-0.003-0.154-0.009-0.549 2.152-2.501 3.743-4.823 3.743s-4.274-1.591-4.823-3.743c-0.051 0.006-0.102 0.009-0.154 0.009h-2.489c-0.687 0-1.244-0.557-1.244-1.244s0.557-1.244 1.244-1.244h2.489c0.052 0 0.104 0.003 0.154 0.009zM8.711 18.133c1.375 0 2.489-1.114 2.489-2.489s-1.114-2.489-2.489-2.489c-1.375 0-2.489 1.114-2.489 2.489s1.114 2.489 2.489 2.489zM18.821 25.076c0.549-2.152 2.501-3.743 4.823-3.743s4.274 1.591 4.823 3.743c0.051-0.006 0.102-0.009 0.154-0.009 0.687 0 1.244 0.557 1.244 1.244s-0.557 1.244-1.244 1.244c-0.052 0-0.104-0.003-0.154-0.009-0.549 2.152-2.501 3.743-4.823 3.743s-4.274-1.591-4.823-3.743c-0.051 0.006-0.102 0.009-0.154 0.009h-17.422c-0.687 0-1.244-0.557-1.244-1.244s0.557-1.244 1.244-1.244h17.422c0.052 0 0.104 0.003 0.154 0.009zM23.644 28.8c1.375 0 2.489-1.114 2.489-2.489s-1.114-2.489-2.489-2.489c-1.375 0-2.489 1.114-2.489 2.489s1.114 2.489 2.489 2.489z"
    ></path>
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
