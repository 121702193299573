export enum BrowserStorageEnum {
  LOCAL = 'localStorage',
  SESSION = 'sessionStorage',
}

export enum StorageEnum {
  LOCAL = 'localStorage',
  SESSION = 'sessionStorage',
  MEMORY = 'memoryStorage',
}
