import { computed, ToRefs, unref } from 'vue'
import { FilterOptionsDataType } from '@/components/deals-list/types'
import {
  DealsRequestDataType,
  DealsSegment,
  VisitorClassification,
} from '@/components/deals-list/services/deals/request/types'
import { ComputedRef } from '@vue/reactivity'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  visitorClassifications: ComputedRef<
    {
      segmentCode: string
      classificationCode: string
      content: string
      value: string
    }[]
  >
  removeSegment: (visitorClassificationCode: string, segmentCode: string) => void
} {
  const getRawVisitorClassifications = () =>
    unref(props.dealRequestFilters)?.visitorClassifications || []

  const getVisitorsClassificationByCode = (classificationCode: string) => {
    return getRawVisitorClassifications().find(
      (item: VisitorClassification) => item.code === classificationCode,
    )
  }

  const getVisitorsClassificationWithoutOne = (classificationCode: string) => {
    return getRawVisitorClassifications().filter(
      (item: VisitorClassification) => item.code !== classificationCode,
    )
  }

  const getVisitorsClassificationSegments = (classificationCode: string) => {
    const visitorClassification = getVisitorsClassificationByCode(classificationCode)

    return visitorClassification?.segments || []
  }

  const getClassificationSegmentsWithoutOne = (classificationCode: string, segmentCode: string) => {
    const segments = getVisitorsClassificationSegments(classificationCode)

    return segments.filter((item: DealsSegment) => item.code !== segmentCode)
  }

  const getVisitorsClassificationSegmentValue = (
    classificationCode: string,
    segmentCode: string,
  ) => {
    return (
      getVisitorsClassificationSegments(classificationCode).find(
        (item) => item.code === segmentCode,
      )?.visitors || 0
    )
  }

  const visitorClassifications = computed<
    {
      segmentCode: string
      classificationCode: string
      content: string
      value: string
    }[]
  >(() => {
    // eslint-disable-next-line unicorn/no-array-reduce
    return unref(props.filterOptionsData).visitorClassifications.reduce(
      (accClassifications, visitorClassification) => {
        return [
          ...accClassifications,
          // eslint-disable-next-line unicorn/no-array-reduce
          ...visitorClassification.segments.reduce((accSegments, segment) => {
            const value = getVisitorsClassificationSegmentValue(
              visitorClassification.code,
              segment.code,
            )

            return value === 0
              ? accSegments
              : [
                  ...accSegments,
                  {
                    classificationCode: visitorClassification.code,
                    segmentCode: segment.code,
                    content: segment.name,
                    value: getVisitorsClassificationSegmentValue(
                      visitorClassification.code,
                      segment.code,
                    ).toString(),
                  },
                ]
          }, []),
        ]
      },
      [],
    )
  })

  function removeSegment(visitorClassificationCode: string, segmentCode: string): void {
    const newRequest = {
      ...unref(props.dealRequestFilters),
      visitorClassifications: [
        ...getVisitorsClassificationWithoutOne(visitorClassificationCode),
        {
          code: visitorClassificationCode,
          segments: getClassificationSegmentsWithoutOne(visitorClassificationCode, segmentCode),
        },
      ],
    }

    emit('update', newRequest as DealsRequestDataType)
  }

  return {
    visitorClassifications,
    removeSegment,
  }
}
