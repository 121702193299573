import {
  DealDetailsResponse,
  DealPricesResponse,
} from '@/components/deal-details/services/deal/response/types'
import client from '@/services/client'

import contracts from '@/services/config/api/contracts'

import type { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'
import { DomainContext } from '@/services/context/domain'
import { CultureName } from '@/types/common'
import { DealDetailsError } from '@/components/deal-details/services/deal/response/errors'
import {
  DealDetailsRequestDataType,
  DealDetailsRequestType,
  DealPricesRequestDataType,
} from '@/components/deal-details/services/deal/request/types'
import { dateFormat } from '@/services/string-helpers/formatters'

export interface DealDetailsRepositoryInterface {
  get(
    slugKey: string,
    request: DealDetailsRequestDataType,
    locale: CultureName,
  ): Promise<DealDetailsResponse>
}

export default class DealDetailsRepository implements DealDetailsRepositoryInterface {
  constructor(
    private domainContext: DomainContext,
    private endpointLocatorProvider: EndpointLocatorProvider,
  ) {}

  async get(
    slugKey: string,
    requestData: Partial<DealDetailsRequestDataType>,
    locale: CultureName,
  ): Promise<DealDetailsResponse> {
    const locator = this.endpointLocatorProvider('GET_DEAL_DETAILS', slugKey)
    const request = client<DealDetailsResponse, DealDetailsRequestType, DealDetailsError>(
      locator,
      contracts.GET_DEAL_DETAILS,
      DealDetailsError,
    )

    const normalizedRequestData = {
      ...requestData,
      ...(requestData.visitDate !== undefined
        ? { visitDate: dateFormat(requestData.visitDate) }
        : {}),
    } as Partial<DealDetailsRequestDataType>
    return await request({
      requestData: normalizedRequestData,
      domain: this.domainContext.domain,
      locale,
    } as DealDetailsRequestType)
  }

  async getPrices(
    slugKey: string,
    requestData: Partial<DealPricesRequestDataType>,
    locale: CultureName,
  ): Promise<DealPricesResponse> {
    const locator = this.endpointLocatorProvider('GET_DEAL_DETAILS', slugKey + '/price')
    const request = client<DealPricesResponse, DealDetailsRequestType, DealDetailsError>(
      locator,
      contracts.GET_DEAL_DETAILS,
      DealDetailsError,
    )

    const normalizedRequestData = {
      ...requestData,
      ...(requestData.visitDate !== undefined
        ? { visitDate: dateFormat(requestData.visitDate) }
        : {}),
    } as Partial<DealDetailsRequestDataType>
    return await request({
      requestData: normalizedRequestData,
      domain: this.domainContext.domain,
      locale,
    } as DealDetailsRequestType)
  }
}
