import type { Ref, WritableComputedRef } from 'vue'
import { CultureName } from '@/types/common'

export const SORT_DIRECTIONS = ['ascending', 'descending'] as const
export const IS_INCLUSIVE_DEFAULT = true

export type SortDirection = typeof SORT_DIRECTIONS[number]

export interface DealsCustomFilter {
  readonly property: string
  readonly values: string[]
}

export interface DealsSegment {
  readonly code: string
  readonly visitors: number
}

export interface VisitorClassification {
  readonly code: string
  readonly segments: DealsSegment[]
}

export interface DealsSorting {
  readonly property: string
  readonly direction: SortDirection
}

export interface DealsRequestDataType {
  customFilters?: DealsCustomFilter[]
  themes?: string[]
  resourceFilters?: string[]
  visitorClassifications?: VisitorClassification[]
  sortings?: DealsSorting[]
  isInclusive?: boolean
  visitDate?: string
}

export interface DealsRequestHookType {
  readonly customFilters: Ref<DealsCustomFilter[]>
  readonly themes: Ref<string[]>
  readonly resourceFilters: Ref<string[]>
  readonly visitorClassifications: Ref<VisitorClassification[]>
  readonly sortings: Ref<DealsSorting[]>
  readonly isInclusive: Ref<boolean>
  readonly visitDate: Ref<string | undefined>
  readonly requestData: WritableComputedRef<Partial<DealsRequestDataType>>
}

export interface DealsExcerptsRequestType {
  readonly requestData: DealsRequestDataType
  readonly locale: CultureName
  readonly domain: string
}
