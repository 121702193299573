import { utcToZonedTime } from 'date-fns-tz'
import { format } from 'date-fns'
import { pipe } from 'ramda'

/** @type Intl.DateTimeFormatOptions */
const defaultOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: undefined,
}

/** @type { (options?: Intl.DateTimeFormatOptions) => (locale:string) => Intl.DateTimeFormat } */
const getDateTimeFormatter = (options) => (locale) =>
  new Intl.DateTimeFormat(locale, { ...defaultOptions, ...options })

/** @type { (options?: Intl.DateTimeFormatOptions) => (locale: string) => (timezone: string) => (utcString: string) => string } */
export const fromUtc = (options) => (locale) => (timezone) =>
  pipe((s) => utcToZonedTime(s, timezone), getDateTimeFormatter(options || {})(locale).format)

export const formatShortDateFromUtc = fromUtc({
  hour: undefined,
  minute: undefined,
})

export const formatTimeFromUtc = fromUtc({
  year: undefined,
  month: undefined,
  day: undefined,
})

export const formatDate = (options) => (locale) => (date) =>
  getDateTimeFormatter(options || {})(locale).format(new Date(date))

export const formatShortDate = formatDate({
  hour: undefined,
  minute: undefined,
})

export const formatTimeFromDate = formatDate({
  year: undefined,
  month: undefined,
  day: undefined,
})

export const formatTime = (locale, time) => {
  // A dummy date is needed in order time to be formatted properly
  const generateDummyDateWithTime = `2022-07-14T${time}`
  return formatDate({
    year: undefined,
    month: undefined,
    day: undefined,
  })(locale)(generateDummyDateWithTime)
}

export const getShortDateForGivenFormat = ({ date, dateFormat }) =>
  format(new Date(date), dateFormat)

export const getShortDateForGivenFormatIgnoringTimezone = ({ date, dateFormat }) => {
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)
  return format(dtDateOnly, dateFormat)
}

export const getShortLocalizedDate = ({ locale, date }) => {
  return new Intl.DateTimeFormat(locale, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(new Date(date))
}

export const getLocaleDateFormatStringForFlatPickr = (locale) => {
  const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date())
  return formatObj
    .map((obj) => {
      switch (obj.type) {
        case 'day':
          return 'd'
        case 'month':
          return 'm'
        case 'year':
          return 'Y'
        default:
          return obj.value
      }
    })
    .join('')
}

export const convertDateFormatStringToFlatPickrDateFormat = (format) =>
  (format || '')
    .replace(/d+/gi, 'd') // d - Day of the month, 2 digits with leading zeros	01 to 31
    .replace(/m+/gi, 'm') // m - Numeric representation of a month, with leading zero	01 through 12
    .replace(/y+/gi, 'Y') // Y - A full numeric representation of a year, 4 digits	1999 or 2022
