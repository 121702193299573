<template>
  <svg viewBox="0 0 32 32">
    <path
      d="M7.6666 17L0.351805 9.88652H0.351804C-0.173744 9.28972 -0.102293 8.39161 0.511394 7.88052C1.05909 7.42439 1.86688 7.42439 2.41457 7.88052L7.66659 12.988L17.5855 3.34212V3.34212C18.1991 2.83104 19.1227 2.90052 19.6482 3.49732C20.1173 4.02995 20.1173 4.8155 19.6482 5.34812L7.6666 17Z"
      fill="#50BC86"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
