import { ManagedApiError } from '@/services/client/error'
import { OpenDatesErrorResponseType } from '@/services/business-unit/open-dates/types'

export class OpenDatesError extends ManagedApiError {
  constructor(
    protected readonly httpCode: number | undefined,
    public readonly errorObject: OpenDatesErrorResponseType,
    message?: string | undefined,
  ) {
    super(httpCode, errorObject, message)
  }
}
