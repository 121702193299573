import { Roarr as roarr, Logger as RoarrLogger } from 'roarr'

import config from '@/services/config'
import { RoarrGlobalState } from 'roarr/dist/src/types'
import { nanoid } from 'nanoid'
import write from './browser-write'

declare let globalThis: {
  ROARR: RoarrGlobalState
}

globalThis.ROARR.write = write

export type Logger = RoarrLogger

export default roarr.child({
  mode: config.mode,
  traceId: nanoid(),
})
