import initOpenDatesStorage from '@/services/storage'
import getBrowserStorage from '@/services/storage/browser-storage-provider'
import { BrowserStorageEnum } from '@/services/storage/types'

export const STORAGE_PREFIX = 'OPEN_DATES_'

const storage = initOpenDatesStorage<string[]>(
  getBrowserStorage(BrowserStorageEnum.SESSION),
  STORAGE_PREFIX,
)

export default storage
