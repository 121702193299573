<template>
  <svg width="55" height="31" viewBox="0 0 55 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M37.4962 19.7344C38.3981 19.7344 39.2029 20.0593 39.8326 20.6199L39.8598 20.6444L40.2004 19.9993L42 19.9991V29.9038L39.3752 31V27.8168C38.8312 28.1621 38.1932 28.355 37.4962 28.355C35.1392 28.355 33.4544 26.5607 33.4544 24.0404C33.4544 21.5286 35.1309 19.7344 37.4962 19.7344ZM15.6954 17V20.2235C16.2619 19.8662 16.9304 19.6664 17.6624 19.6662C20.0997 19.6662 21.8213 21.4541 21.8213 23.9659C21.8213 26.4776 20.0997 28.2656 17.6624 28.2656C16.7386 28.2656 15.9178 27.9494 15.2747 27.4008L15.0176 28.0009H13V18.0962L15.6954 17ZM28.1065 20.0078V22.444H27.0762C26.1367 22.444 25.7432 22.8573 25.7432 23.8432V27.7734H23.0894V20.0446H24.8673L25.2813 20.9024C25.8246 20.2719 26.4865 20.0078 27.4035 20.0078H28.1065ZM32.1863 20.0078V27.7734H29.4297V20.0078H32.1863ZM37.8513 22.1908C36.8568 22.1908 36.1258 22.9849 36.1258 24.0404C36.1258 25.0958 36.8629 25.8899 37.8513 25.8899C38.8458 25.8899 39.5768 25.0958 39.5768 24.0404C39.5768 22.9849 38.8458 22.1908 37.8513 22.1908ZM17.2977 22.1225C16.2764 22.1225 15.5258 22.9104 15.5258 23.9659C15.5258 25.0127 16.2764 25.8005 17.2977 25.8005C18.3127 25.8005 19.0696 25.0127 19.0696 23.9659C19.0696 22.9104 18.319 22.1225 17.2977 22.1225Z"
      fill="#4F5052"
    />
    <path
      d="M0 9.4V2.24H0.76L0.84 3.06C1 2.82 1.22667 2.60333 1.52 2.41C1.82 2.21667 2.2 2.12 2.66 2.12C3.15333 2.12 3.58333 2.23333 3.95 2.46C4.31667 2.68667 4.6 2.99667 4.8 3.39C5.00667 3.78333 5.11 4.23 5.11 4.73C5.11 5.23 5.00667 5.67667 4.8 6.07C4.6 6.45667 4.31333 6.76333 3.94 6.99C3.57333 7.21 3.14333 7.32 2.65 7.32C2.24333 7.32 1.88 7.23667 1.56 7.07C1.24667 6.90333 1.00667 6.67 0.84 6.37V9.4H0ZM2.56 6.59C2.89333 6.59 3.18667 6.51333 3.44 6.36C3.69333 6.2 3.89333 5.98 4.04 5.7C4.18667 5.42 4.26 5.09333 4.26 4.72C4.26 4.34667 4.18667 4.02 4.04 3.74C3.89333 3.46 3.69333 3.24333 3.44 3.09C3.18667 2.93 2.89333 2.85 2.56 2.85C2.22667 2.85 1.93333 2.93 1.68 3.09C1.42667 3.24333 1.22667 3.46 1.08 3.74C0.933333 4.02 0.86 4.34667 0.86 4.72C0.86 5.09333 0.933333 5.42 1.08 5.7C1.22667 5.98 1.42667 6.2 1.68 6.36C1.93333 6.51333 2.22667 6.59 2.56 6.59Z"
      fill="#A1A2A3"
    />
    <path
      d="M8.5393 7.32C8.07263 7.32 7.65263 7.21333 7.2793 7C6.90596 6.78667 6.6093 6.48667 6.3893 6.1C6.17596 5.70667 6.0693 5.24667 6.0693 4.72C6.0693 4.19333 6.1793 3.73667 6.3993 3.35C6.6193 2.95667 6.91596 2.65333 7.2893 2.44C7.6693 2.22667 8.09263 2.12 8.5593 2.12C9.02596 2.12 9.44596 2.22667 9.8193 2.44C10.1926 2.65333 10.486 2.95667 10.6993 3.35C10.9193 3.73667 11.0293 4.19333 11.0293 4.72C11.0293 5.24667 10.9193 5.70667 10.6993 6.1C10.4793 6.48667 10.1793 6.78667 9.7993 7C9.42596 7.21333 9.00596 7.32 8.5393 7.32ZM8.5393 6.6C8.82596 6.6 9.09263 6.53 9.3393 6.39C9.58596 6.25 9.78596 6.04 9.9393 5.76C10.0926 5.48 10.1693 5.13333 10.1693 4.72C10.1693 4.30667 10.0926 3.96 9.9393 3.68C9.79263 3.4 9.59596 3.19 9.3493 3.05C9.10263 2.91 8.8393 2.84 8.5593 2.84C8.27263 2.84 8.00596 2.91 7.7593 3.05C7.51263 3.19 7.31263 3.4 7.1593 3.68C7.00596 3.96 6.9293 4.30667 6.9293 4.72C6.9293 5.13333 7.00596 5.48 7.1593 5.76C7.31263 6.04 7.5093 6.25 7.7493 6.39C7.99596 6.53 8.2593 6.6 8.5393 6.6Z"
      fill="#A1A2A3"
    />
    <path
      d="M13.0117 7.2L11.5617 2.24H12.4017L13.4517 6.13L14.6117 2.24H15.5617L16.7317 6.13L17.7717 2.24H18.6217L17.1717 7.2H16.3117L15.0917 3.11L13.8717 7.2H13.0117Z"
      fill="#A1A2A3"
    />
    <path
      d="M21.5952 7.32C21.1219 7.32 20.7019 7.21333 20.3352 7C19.9686 6.78 19.6786 6.47667 19.4652 6.09C19.2586 5.70333 19.1552 5.24667 19.1552 4.72C19.1552 4.2 19.2586 3.74667 19.4652 3.36C19.6719 2.96667 19.9586 2.66333 20.3252 2.45C20.6986 2.23 21.1286 2.12 21.6152 2.12C22.0952 2.12 22.5086 2.23 22.8552 2.45C23.2086 2.66333 23.4786 2.94667 23.6652 3.3C23.8519 3.65333 23.9452 4.03333 23.9452 4.44C23.9452 4.51333 23.9419 4.58667 23.9352 4.66C23.9352 4.73333 23.9352 4.81667 23.9352 4.91H19.9852C20.0052 5.29 20.0919 5.60667 20.2452 5.86C20.4052 6.10667 20.6019 6.29333 20.8352 6.42C21.0752 6.54667 21.3286 6.61 21.5952 6.61C21.9419 6.61 22.2319 6.53 22.4652 6.37C22.6986 6.21 22.8686 5.99333 22.9752 5.72H23.8052C23.6719 6.18 23.4152 6.56333 23.0352 6.87C22.6619 7.17 22.1819 7.32 21.5952 7.32ZM21.5952 2.83C21.1952 2.83 20.8386 2.95333 20.5252 3.2C20.2186 3.44 20.0419 3.79333 19.9952 4.26H23.1152C23.0952 3.81333 22.9419 3.46333 22.6552 3.21C22.3686 2.95667 22.0152 2.83 21.5952 2.83Z"
      fill="#A1A2A3"
    />
    <path
      d="M25.0586 7.2V2.24H25.8186L25.8886 3.19C26.0419 2.86333 26.2753 2.60333 26.5886 2.41C26.9019 2.21667 27.2886 2.12 27.7486 2.12V3H27.5186C27.2253 3 26.9553 3.05333 26.7086 3.16C26.4619 3.26 26.2653 3.43333 26.1186 3.68C25.9719 3.92667 25.8986 4.26667 25.8986 4.7V7.2H25.0586Z"
      fill="#A1A2A3"
    />
    <path
      d="M30.7359 7.32C30.2625 7.32 29.8425 7.21333 29.4759 7C29.1092 6.78 28.8192 6.47667 28.6059 6.09C28.3992 5.70333 28.2959 5.24667 28.2959 4.72C28.2959 4.2 28.3992 3.74667 28.6059 3.36C28.8125 2.96667 29.0992 2.66333 29.4659 2.45C29.8392 2.23 30.2692 2.12 30.7559 2.12C31.2359 2.12 31.6492 2.23 31.9959 2.45C32.3492 2.66333 32.6192 2.94667 32.8059 3.3C32.9925 3.65333 33.0859 4.03333 33.0859 4.44C33.0859 4.51333 33.0825 4.58667 33.0759 4.66C33.0759 4.73333 33.0759 4.81667 33.0759 4.91H29.1259C29.1459 5.29 29.2325 5.60667 29.3859 5.86C29.5459 6.10667 29.7425 6.29333 29.9759 6.42C30.2159 6.54667 30.4692 6.61 30.7359 6.61C31.0825 6.61 31.3725 6.53 31.6059 6.37C31.8392 6.21 32.0092 5.99333 32.1159 5.72H32.9459C32.8125 6.18 32.5559 6.56333 32.1759 6.87C31.8025 7.17 31.3225 7.32 30.7359 7.32ZM30.7359 2.83C30.3359 2.83 29.9792 2.95333 29.6659 3.2C29.3592 3.44 29.1825 3.79333 29.1359 4.26H32.2559C32.2359 3.81333 32.0825 3.46333 31.7959 3.21C31.5092 2.95667 31.1559 2.83 30.7359 2.83Z"
      fill="#A1A2A3"
    />
    <path
      d="M36.4492 7.32C35.9559 7.32 35.5226 7.20667 35.1492 6.98C34.7826 6.75333 34.4959 6.44333 34.2892 6.05C34.0892 5.65667 33.9892 5.21 33.9892 4.71C33.9892 4.21 34.0926 3.76667 34.2992 3.38C34.5059 2.98667 34.7926 2.68 35.1592 2.46C35.5259 2.23333 35.9592 2.12 36.4592 2.12C36.8659 2.12 37.2259 2.20333 37.5392 2.37C37.8526 2.53667 38.0959 2.77 38.2692 3.07V0H39.1092V7.2H38.3492L38.2692 6.38C38.1092 6.62 37.8792 6.83667 37.5792 7.03C37.2792 7.22333 36.9026 7.32 36.4492 7.32ZM36.5392 6.59C36.8726 6.59 37.1659 6.51333 37.4192 6.36C37.6792 6.2 37.8792 5.98 38.0192 5.7C38.1659 5.42 38.2392 5.09333 38.2392 4.72C38.2392 4.34667 38.1659 4.02 38.0192 3.74C37.8792 3.46 37.6792 3.24333 37.4192 3.09C37.1659 2.93 36.8726 2.85 36.5392 2.85C36.2126 2.85 35.9192 2.93 35.6592 3.09C35.4059 3.24333 35.2059 3.46 35.0592 3.74C34.9192 4.02 34.8492 4.34667 34.8492 4.72C34.8492 5.09333 34.9192 5.42 35.0592 5.7C35.2059 5.98 35.4059 6.2 35.6592 6.36C35.9192 6.51333 36.2126 6.59 36.5392 6.59Z"
      fill="#A1A2A3"
    />
    <path
      d="M45.775 7.32C45.3683 7.32 45.005 7.23667 44.685 7.07C44.3717 6.90333 44.1317 6.67 43.965 6.37L43.885 7.2H43.125V0H43.965V3.06C44.125 2.82 44.3517 2.60333 44.645 2.41C44.945 2.21667 45.325 2.12 45.785 2.12C46.2783 2.12 46.7083 2.23333 47.075 2.46C47.4417 2.68667 47.725 2.99667 47.925 3.39C48.1317 3.78333 48.235 4.23 48.235 4.73C48.235 5.23 48.1317 5.67667 47.925 6.07C47.725 6.45667 47.4383 6.76333 47.065 6.99C46.6983 7.21 46.2683 7.32 45.775 7.32ZM45.685 6.59C46.0183 6.59 46.3117 6.51333 46.565 6.36C46.8183 6.2 47.0183 5.98 47.165 5.7C47.3117 5.42 47.385 5.09333 47.385 4.72C47.385 4.34667 47.3117 4.02 47.165 3.74C47.0183 3.46 46.8183 3.24333 46.565 3.09C46.3117 2.93 46.0183 2.85 45.685 2.85C45.3517 2.85 45.0583 2.93 44.805 3.09C44.5517 3.24333 44.3517 3.46 44.205 3.74C44.0583 4.02 43.985 4.34667 43.985 4.72C43.985 5.09333 44.0583 5.42 44.205 5.7C44.3517 5.98 44.5517 6.2 44.805 6.36C45.0583 6.51333 45.3517 6.59 45.685 6.59Z"
      fill="#A1A2A3"
    />
    <path
      d="M49.5439 9.4L50.7739 6.72H50.4839L48.5139 2.24H49.4239L51.0639 6.1L52.8039 2.24H53.6739L50.4239 9.4H49.5439Z"
      fill="#A1A2A3"
    />
  </svg>
</template>
