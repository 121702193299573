import { VisitorClassification } from '@/components/booking-upsells/services/upsells/request/types'
import { forEach, clone } from 'ramda'

export const mergeVisitorClassifications = (
  visitorClassificationFromDeal: VisitorClassification[],
  visitorClassificationFromSC: VisitorClassification[],
): VisitorClassification[] => {
  interface SingleDealSegment {
    readonly code: string
    visitors: number
  }

  const visitorClassification = clone(visitorClassificationFromDeal)
  forEach((segmentOfDeal: SingleDealSegment) => {
    forEach((segmentFromSession: SingleDealSegment) => {
      if (segmentOfDeal.code === segmentFromSession.code) {
        segmentOfDeal.visitors = segmentFromSession.visitors
      }
    })(visitorClassificationFromSC[0].segments)
  })(visitorClassification[0].segments)

  return visitorClassification
}
