import { computed, ToRefs, unref } from 'vue'
import { FilterOptionsDataType } from '@/components/deals-list/types'
import {
  DealsCustomFilter,
  DealsRequestDataType,
} from '@/components/deals-list/services/deals/request/types'
import { ComputedRef } from '@vue/reactivity'
import { map } from 'ramda'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  customFilters: ComputedRef<
    {
      filterCode: string
      key: string
      content: string
    }[]
  >
  removeCustomFilter: (filterCode: string, key: string) => void
} {
  const customFilters = computed(() => {
    const customFilters = unref(props.dealRequestFilters)?.customFilters || []
    const customFiltersInLowerCase = convertCustomFilterValuesToLowerCase(customFilters)
    const customFiltersOptions = unref(props.filterOptionsData)?.filters || []

    // eslint-disable-next-line unicorn/no-array-reduce
    return customFiltersOptions.reduce((acc, filter) => {
      const filterValue = customFiltersInLowerCase.find((item) => item.property === filter.code)

      if (!filterValue) {
        return acc
      }

      const options = filter.options
        .filter((option) => filterValue.values.includes(option.key))
        .map((option) => {
          return {
            filterCode: filter.code,
            key: option.key,
            content: option.content,
          }
        })

      return [...acc, ...options]
    }, [])
  })

  function removeCustomFilter(filterCode: string, key: string): void {
    const requestFilters = unref(props.dealRequestFilters)
    const newRequest = {
      ...requestFilters,
      customFilters: requestFilters?.customFilters?.map((item: DealsCustomFilter) => {
        if (item.property !== filterCode) {
          return item
        }

        return {
          ...item,
          values: item.values.filter((value: string) => value.toLowerCase() !== key),
        }
      }),
    }

    emit('update', newRequest as DealsRequestDataType)
  }

  return {
    customFilters,
    removeCustomFilter,
  }
}

const convertCustomFilterValuesToLowerCase = map((cf: { property: string; values: string[] }) => {
  const values = map((f: string) => f.toLowerCase())(cf.values)
  cf.values = values
  return cf
})
