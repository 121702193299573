import { ref, Ref } from 'vue'

export interface UiStateType {
  isScrolledWizardNavigation: Ref<boolean>
}

export default function useUiState(): UiStateType {
  const isScrolledWizardNavigation = ref<boolean>(false)

  return {
    isScrolledWizardNavigation,
  }
}
