import initBusinessUnitStorage from '@/services/storage'
import { BusinessUnit } from '@/services/business-unit/settings/types'
import getBrowserStorage from '@/services/storage/browser-storage-provider'
import { BrowserStorageEnum } from '@/services/storage/types'

export const STORAGE_PREFIX = 'BUSINESS_UNIT_REPOSITORY_'

const storage = initBusinessUnitStorage<BusinessUnit>(
  getBrowserStorage(BrowserStorageEnum.SESSION),
  STORAGE_PREFIX,
)

export default storage
