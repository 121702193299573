import { OpenDatesRequestType, OpenDatesResponse } from '@/services/business-unit/open-dates/types'
import client from '@/services/client'
import { OpenDatesError } from '@/services/business-unit/open-dates/error'
import contracts from '@/services/config/api/contracts'

import type { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'
import { DomainContext } from '@/services/context/domain'
import cache from '@/services/storage/caching-decorator'
import storage from '@/services/business-unit/open-dates/storage'
import { CultureName } from '@/types/common'

export interface OpenDatesRepositoryInterface {
  get(locale: CultureName): Promise<OpenDatesResponse>
}

export const CACHE_NAMESPACE = 'OpenDatesRepository:get'

export default class OpenDatesRepository implements OpenDatesRepositoryInterface {
  constructor(
    private readonly domainContext: DomainContext,
    private readonly endpointLocatorProvider: EndpointLocatorProvider,
  ) {}
  @cache<string[]>(storage, {
    namespace: CACHE_NAMESPACE,
  })
  async get(locale: CultureName): Promise<OpenDatesResponse> {
    const locator = this.endpointLocatorProvider('GET_BUSINESS_UNIT_OPENDATES')
    const request = client<OpenDatesResponse, OpenDatesRequestType, OpenDatesError>(
      locator,
      contracts.GET_BUSINESS_UNIT_OPENDATES,
      OpenDatesError,
    )

    return await request({
      domain: this.domainContext.domain,
      locale,
    } as OpenDatesRequestType)
  }
}
