import { ManagedApiError } from '@/services/client/error'
import { TimesErrorResponseType } from '@/components/booking-time-selection/services/response/types'

export class TimesError extends ManagedApiError {
  constructor(
    protected readonly httpCode: number | undefined,
    public readonly errorObject: TimesErrorResponseType,
    message?: string | undefined,
  ) {
    super(httpCode, errorObject, message)
  }
}
