import {
  serialize,
  SerializeType,
  unserialize,
  UnserializeType,
} from '@/services/storage/serializer'
import MemoryStorage from '@/services/storage/memory'

export interface StorageType<T> {
  getItem(key: string): T | undefined
  setItem(key: string, value: T): void
  removeItem(key: string): void
  clear(): void
  get length(): number
  key(index: number): string | undefined
  hasItem(key: string): boolean
}

export default <T>(
  storage: Storage = new MemoryStorage(),
  prefix = '',
  serializer: SerializeType<T> = serialize,
  unserializer: UnserializeType<T> = unserialize,
): StorageType<T> => {
  const withPrefix = (key: string): string => `${prefix}${key}`
  const withoutPrefix = (keyWithPrefix: string | undefined): string | undefined =>
    typeof keyWithPrefix === 'undefined' ? keyWithPrefix : keyWithPrefix.slice(prefix.length)

  const getItem = (key: string): T | undefined => {
    const value = storage.getItem(withPrefix(key))
    return value === null ? undefined : unserializer(value)
  }

  return {
    getItem,
    setItem(key: string, value: T): void {
      storage.setItem(withPrefix(key), serializer(value))
    },
    removeItem(key: string): void {
      storage.removeItem(withPrefix(key))
    },
    clear(): void {
      storage.clear()
    },
    get length(): number {
      return storage.length
    },
    key(index: number): string | undefined {
      return withoutPrefix(storage.key(index) ?? undefined)
    },
    hasItem(key: string): boolean {
      return typeof getItem(key) !== 'undefined'
    },
  }
}
