import { computed, ToRefs, unref, WritableComputedRef } from 'vue'
import { DealsRequestDataType } from '@/components/deals-list/services/deals/request/types'
import { FilterOptionsDataType, ThemeOptionType } from '@/components/deals-list/types'
import { ComputedRef } from '@vue/reactivity'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  themeOptions: ComputedRef<ThemeOptionType[]>
  themeValue: WritableComputedRef<string | undefined>
} {
  const themeOptions = computed(() => {
    return unref(props.filterOptionsData).theme.filter((item) => item.active)
  })

  const themeValue = computed<string | undefined>({
    get() {
      const themes = unref(props.dealRequestFilters)?.themes
      return themes && themes.length > 0 ? themes[0] : undefined
    },
    set(value) {
      const newRequest = {
        ...unref(props.dealRequestFilters),
        themes: value ? [value] : [],
      }

      emit('update', newRequest as DealsRequestDataType)
    },
  })

  return {
    themeOptions,
    themeValue,
  }
}
