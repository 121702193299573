import {
  ApiRequestArguments,
  BodyArguments,
  PathArguments,
  QueryStringArguments,
  HeadersArguments,
} from '@/services/client/types'

export default <
    ResultType extends QueryStringArguments | PathArguments | BodyArguments | HeadersArguments,
    RequestArgumentsType extends ApiRequestArguments,
    TransformerResultType = unknown,
  >(
    valueTransformer: (key: string, value: unknown) => [string, TransformerResultType] = (
      key: string,
      value: unknown,
    ): [string, TransformerResultType] => [key, value as TransformerResultType],
  ) =>
  (args: string[] | undefined, apiRequestValues: RequestArgumentsType): ResultType =>
    Object.fromEntries(
      (args || [])
        .filter((key) => key in apiRequestValues)
        .map((key) => valueTransformer(key, apiRequestValues[key])),
    ) as ResultType
