<template>
  <svg viewBox="-40 -7 250 32">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.1763 0.618827C15.1763 3.14726 15.1763 3.14726 15.1763 3.14726C15.1763 3.28479 15.0335 3.41703 14.9065 3.41703C4.06813 3.41703 4.06813 3.41703 4.06813 3.41703C4.06813 8.56382 4.06813 8.56382 4.06813 8.56382C13.0129 8.56382 13.0129 8.56382 13.0129 8.56382C13.1398 8.56382 13.2826 8.65374 13.2826 8.83359C13.2826 11.3197 13.2826 11.3197 13.2826 11.3197C13.2826 11.4995 13.1398 11.6371 13.0129 11.6371C4.06813 11.6371 4.06813 11.6371 4.06813 11.6371C4.06813 19.2224 4.06813 19.2224 4.06813 19.2224C4.06813 19.4022 3.9835 19.4921 3.79836 19.4921C1.09008 19.4921 1.09008 19.4921 1.09008 19.4921C0.963133 19.4921 0.820312 19.4022 0.820312 19.2224C0.820312 0.624115 0.820312 0.624122 0.820312 0.624122C0.820312 0.444275 0.963133 0.354346 1.09008 0.354346C14.9065 0.354346 14.9065 0.354346 14.9065 0.354346C15.0335 0.349057 15.1763 0.43898 15.1763 0.618827Z"
      fill="#1077EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.4089 11.4044C30.425 8.64851 29.3036 5.85031 28.1769 3.36949C27.5527 3.36949 27.5527 3.36949 27.5527 3.36949C26.4261 5.85031 25.3047 8.64851 24.3049 11.4044H31.4089ZM37.1216 19.4869C34.1595 19.4869 34.1595 19.4869 34.1595 19.4869C33.9743 19.4869 33.8897 19.397 33.8474 19.2595C33.4348 17.6779 32.9481 16.0117 32.4086 14.3401C23.3211 14.3401 23.3211 14.3401 23.3211 14.3401C22.7815 16.0117 22.279 17.6832 21.8823 19.2595C21.8823 19.397 21.7553 19.4869 21.5702 19.4869C18.735 19.4869 18.735 19.4869 18.735 19.4869C18.5498 19.4869 18.4229 19.397 18.4652 19.1695C20.2161 13.0759 22.8238 6.34753 25.4316 0.70881C25.5744 0.439041 25.7437 0.349121 25.9711 0.349121C29.8431 0.349121 29.8431 0.349121 29.8431 0.349121C30.1129 0.349121 30.2399 0.439041 30.3827 0.70881C33.0328 6.35282 35.6353 13.0759 37.3491 19.1695C37.3914 19.397 37.3068 19.4869 37.1216 19.4869Z"
      fill="#1077EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.8359 10.1825C41.8359 4.18408 45.7502 0 51.2938 0C51.6905 0 51.6905 0 51.6905 0C55.2081 0 57.9163 1.81962 59.3498 4.36393C59.4345 4.54377 59.3921 4.6813 59.2493 4.77123C56.8267 5.95081 56.8267 5.95081 56.8267 5.95081C56.6839 6.04073 56.4564 6.04073 56.3718 5.86088C55.3297 4.08887 53.8486 3.13674 51.6006 3.13674C51.2038 3.13674 51.2038 3.13674 51.2038 3.13674C47.6862 3.13674 45.253 5.95609 45.253 10.045C45.253 14.1338 47.6439 16.8633 51.2038 16.8633C51.6006 16.8633 51.6006 16.8633 51.6006 16.8633C53.891 16.8633 55.2451 16.091 56.2448 14.8638C56.3718 14.7263 56.5569 14.6839 56.6839 14.7739C59.1594 16.0011 59.1594 16.0011 59.1594 16.0011C59.244 16.0487 59.3022 16.091 59.3022 16.1809C59.3022 16.2285 59.244 16.3184 59.2017 16.4084C57.7206 18.6353 54.97 20 51.6376 20C51.2409 20 51.2409 20 51.2409 20C45.5651 20 41.8359 16.091 41.8359 10.1825Z"
      fill="#1077EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M80.9746 16.6887C80.9746 19.2172 80.9746 19.2172 80.9746 19.2172C80.9746 19.397 80.8899 19.4869 80.7048 19.4869C66.3752 19.4869 66.3752 19.4869 66.3752 19.4869C66.1901 19.4869 66.1055 19.397 66.1055 19.2172C66.1055 0.618898 66.1055 0.618888 66.1055 0.618888C66.1055 0.439041 66.1901 0.349121 66.3752 0.349121C80.472 0.349121 80.472 0.349121 80.472 0.349121C80.6149 0.349121 80.7418 0.439041 80.7418 0.618888C80.7418 3.14732 80.7418 3.14732 80.7418 3.14732C80.7418 3.28485 80.6149 3.41709 80.472 3.41709C69.348 3.41709 69.348 3.41709 69.348 3.41709C69.348 8.38403 69.348 8.38403 69.348 8.38403C78.4038 8.38403 78.4038 8.38403 78.4038 8.38403C78.5308 8.38403 78.6736 8.52156 78.6736 8.6538C78.6736 11.1399 78.6736 11.1399 78.6736 11.1399C78.6736 11.3198 78.5308 11.4097 78.4038 11.4097C69.348 11.4097 69.348 11.4097 69.348 11.4097C69.348 16.4189 69.348 16.4189 69.348 16.4189C80.6995 16.4189 80.6995 16.4189 80.6995 16.4189C80.8899 16.4189 80.9746 16.5512 80.9746 16.6887Z"
      fill="#1077EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M102.248 13.9328C102.248 12.1714 101.079 11.3568 98.4769 11.3568C92.0818 11.3568 92.0818 11.3568 92.0818 11.3568C92.0818 16.5036 92.0818 16.5036 92.0818 16.5036C98.4769 16.5036 98.4769 16.5036 98.4769 16.5036C101.122 16.5089 102.248 15.7419 102.248 13.9328ZM97.5618 3.32717C92.0765 3.32717 92.0765 3.32717 92.0765 3.32717C92.0765 8.47396 92.0765 8.47396 92.0765 8.47396C97.5618 8.47396 97.5618 8.47396 97.5618 8.47396C100.037 8.47396 101.164 7.65936 101.164 5.89792C101.164 4.14177 100.043 3.32717 97.5618 3.32717ZM105.581 14.2502C105.581 17.5932 103.015 19.4869 98.4769 19.4869C89.1037 19.4869 89.1038 19.4869 89.1038 19.4869C88.9186 19.4869 88.834 19.397 88.834 19.2172C88.834 0.618898 88.834 0.618888 88.834 0.618888C88.834 0.439041 88.9186 0.349121 89.1038 0.349121C97.6623 0.349121 97.6623 0.349121 97.6623 0.349121C102.021 0.349121 104.454 2.11056 104.454 5.31606C104.454 7.39488 103.37 8.79133 101.206 9.42079C104.269 10.0503 105.581 11.9016 105.581 14.2502Z"
      fill="#1077EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M121.446 16.8632C125.349 16.8632 127.767 14.1391 127.767 10.0026C127.767 5.86613 125.344 3.14198 121.446 3.14198C121.033 3.14198 121.033 3.14198 121.033 3.14198C117.145 3.14198 114.664 5.86613 114.664 10.0026C114.664 14.1391 117.14 16.8632 121.033 16.8632H121.446ZM111.221 10.0026C111.221 4.18403 115.04 0.00524902 121.033 0.00524902C121.446 0.00524902 121.446 0.00524902 121.446 0.00524902C127.396 0.00524902 131.215 4.18403 131.215 10.0026C131.215 15.8212 127.396 20 121.446 20C121.033 20 121.033 20 121.033 20C115.04 20 111.221 15.8212 111.221 10.0026Z"
      fill="#1077EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M146.882 16.8632C150.796 16.8632 153.219 14.1391 153.219 10.0026C153.219 5.86613 150.796 3.14198 146.882 3.14198C146.485 3.14198 146.485 3.14198 146.485 3.14198C142.571 3.14198 140.106 5.86613 140.106 10.0026C140.106 14.1391 142.571 16.8632 146.485 16.8632H146.882ZM136.684 10.0026C136.684 4.18403 140.455 0.00524902 146.48 0.00524902C146.877 0.00524902 146.877 0.00524902 146.877 0.00524902C152.859 0.00524902 156.673 4.18403 156.673 10.0026C156.673 15.8212 152.854 20 146.877 20C146.48 20 146.48 20 146.48 20C140.46 20 136.684 15.8212 136.684 10.0026Z"
      fill="#1077EE"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M179.952 19.4869C176.461 19.4869 176.461 19.4869 176.461 19.4869C176.233 19.4869 176.149 19.4393 175.963 19.2594C173.557 16.3237 170.637 13.4356 168.172 11.3144C166.606 11.3144 166.606 11.3144 166.606 11.3144C166.606 19.2118 166.606 19.2118 166.606 19.2118C166.606 19.3917 166.479 19.4816 166.294 19.4816C163.66 19.4816 163.66 19.4816 163.66 19.4816C163.475 19.4816 163.348 19.3917 163.348 19.2118C163.348 0.613565 163.348 0.613563 163.348 0.613563C163.348 0.433716 163.475 0.343796 163.66 0.343796C166.294 0.343796 166.294 0.343796 166.294 0.343796C166.479 0.343796 166.606 0.433716 166.606 0.613563C166.606 8.15125 166.606 8.15125 166.606 8.15125C168.172 8.15125 168.172 8.15125 168.172 8.15125C170.822 5.76035 173.287 3.142 175.249 0.565958C175.376 0.386112 175.519 0.338501 175.646 0.338501C178.963 0.338501 178.963 0.338501 178.963 0.338501C179.132 0.338501 179.232 0.42843 179.232 0.518354C179.232 0.560671 179.19 0.65588 179.132 0.745803C176.228 4.3586 173.324 7.33665 170.631 9.63763C174.033 12.4834 177.212 15.6837 180.073 19.1166C180.264 19.307 180.179 19.4869 179.952 19.4869Z"
      fill="#1077EE"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
