import { VisitorClassificationsType } from '@/components/booking-upsells/services/upsells/response/types'
import {
  VisitorClassification as VisitorClassificationPricesRequestDataType,
  DealSegment,
} from '@/components/booking-upsells/services/upsells/request/types'
import { map, filter } from 'ramda'

export const getUpsellsPricesRequestData = (
  visitorClassifications: VisitorClassificationsType[],
): VisitorClassificationPricesRequestDataType[] => {
  return map((vc: VisitorClassificationPricesRequestDataType) => {
    const segments = map((s: DealSegment) => {
      if (s.visitors > 0) {
        return {
          code: s.code,
          visitors: s.visitors,
        }
      }
    })(vc.segments) as DealSegment[]
    return {
      code: vc.code,
      segments: filter((s) => s !== undefined)(segments),
    }
  })(visitorClassifications)
}
