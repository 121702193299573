import shoppingCartHook from '@/components/deal-booking/services/shopping-cart/hooks/shopping-cart-hook'
import { WritableComputedRef, ref, Ref, computed } from 'vue'
import type { Time } from '@/components/booking-time-selection/services/response/types'

export default function (): {
  times: WritableComputedRef<Time[]>
  isConceptFlowModalVisible: Ref<boolean>
  isConceptFlow: Ref<boolean>
  isConceptFlowAllowed: Ref<boolean>
  conceptFlowStatus: Ref<string>
  maxTotalVisitors: Ref<number>
  conceptFlow: string
  regularFlow: string
} {
  type conceptStatusType = 'concept' | 'regular'

  const isConceptFlowModalVisible = ref(false)
  const { shoppingCartRef } = shoppingCartHook()
  const isConceptFlow = ref(shoppingCartRef.value.booking?.isConcept || false)
  const isConceptFlowAllowed = ref(shoppingCartRef.value.isConceptFlowAllowed || false)
  const conceptFlow = 'concept'
  const regularFlow = 'regular'
  const conceptFlowStatus = ref<conceptStatusType>(regularFlow)
  const apiTimes = ref<Time[]>([])
  const maxTotalVisitors = ref()
  const dealFromSession = sessionStorage.getItem('DEAL_DETAILS')

  const dealDetails = dealFromSession !== null ? JSON.parse(dealFromSession as string) : undefined
  maxTotalVisitors.value = dealDetails?.quotationThreshold

  const times = computed({
    get(): Time[] {
      return apiTimes.value
    },
    set(value: Time[]) {
      apiTimes.value = value
    },
  })

  conceptFlowStatus.value =
    isConceptFlow.value && isConceptFlowAllowed.value ? conceptFlow : regularFlow

  return {
    times,
    isConceptFlowModalVisible,
    isConceptFlow,
    isConceptFlowAllowed,
    conceptFlowStatus,
    maxTotalVisitors,
    conceptFlow,
    regularFlow,
  }
}
