import urlResolver from './url-resolver'

import type {
  Contract,
  ApiResponse,
  ApiRequestArguments,
  ApiErrorObject,
  PathArguments,
  QueryStringArguments,
  BodyArguments,
  HeadersArguments,
} from '@/services/client/types'

import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { ManagedApiError, UnmanagedApiError } from '@/services/client/error'
import { ResolveUrlArguments } from '@/services/client/types'
import argumentsMapper from '@/services/client/arguments-mapper'
import type { ApiEndpointLocator } from '@/services/config/api/types'

export default function client<
  ReturnType extends ApiResponse,
  RequestArgumentsType extends ApiRequestArguments,
  ManagedApiErrorType extends ManagedApiError,
>(
  { schema, host, port, path }: ApiEndpointLocator,
  contract: Contract,
  ErrorType: new (httpCode: number | undefined, errorObject: ApiErrorObject) => ManagedApiErrorType,
): (requestArguments: RequestArgumentsType) => Promise<ReturnType> {
  const {
    pathArguments,
    queryArguments,
    headersArguments,
    bodyArguments,
    acceptableHttpCodes,
    method,
    argumentKeyMap,
  }: Contract = contract

  const toString = (key: string, value: string): [string, string] => [
    (argumentKeyMap ?? {})[key] ?? key,
    String(value),
  ]

  const unmodifiedValue = (key: string, value: unknown): [string, unknown] => [
    (argumentKeyMap ?? {})[key] ?? key,
    value,
  ]

  const pathMapper = argumentsMapper<PathArguments, RequestArgumentsType, string>(toString)

  const queryMapper = argumentsMapper<QueryStringArguments, RequestArgumentsType, string>(toString)

  const headersMapper = argumentsMapper<HeadersArguments, RequestArgumentsType, string>(toString)

  const bodyMapper = argumentsMapper<BodyArguments, RequestArgumentsType>(unmodifiedValue)

  const resolveUrl = urlResolver({ schema, host, port, path })

  return async function request(requestArguments: RequestArgumentsType): Promise<ReturnType> {
    const resolveUrlArguments = {
      pathArguments: pathMapper(pathArguments, requestArguments),
      query: queryMapper(queryArguments, requestArguments),
    } as ResolveUrlArguments

    const requestConfig = {
      url: resolveUrl(resolveUrlArguments),
      method,
      data: bodyMapper(bodyArguments, requestArguments),
      headers: headersMapper(headersArguments, requestArguments),
      validateStatus: (status: number): boolean => acceptableHttpCodes.includes(status),
    } as AxiosRequestConfig<ReturnType>

    try {
      const response = await axios.request<ReturnType>(requestConfig)

      const data = response.data as ReturnType

      return Object.freeze(data)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        !error ||
        typeof error !== 'object' ||
        !('isAxiosError' in error) ||
        !error.isAxiosError
      ) {
        throw error
      }

      const { response } = error as AxiosError
      const status = response?.status

      if (!response?.data) {
        throw new UnmanagedApiError(status)
      }

      throw new ErrorType(status, response.data as ApiErrorObject)
    }
  }
}
