import { capitalize } from 'lodash'

export default (businessUnitName: string, title = ''): void => {
  const briqBookingsText = 'Briq Bookings'
  const tagline: string = businessUnitName
    ? `${capitalize(businessUnitName)} | ${briqBookingsText}`
    : briqBookingsText
  const documentTitle: string = title ? `${title} | ${tagline}` : tagline
  document.title = documentTitle
}
