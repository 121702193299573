import { BusinessUnit, BusinessUnitRequest } from '@/services/business-unit/settings/types'
import client from '@/services/client'
import { BusinessUnitApiError } from '@/services/business-unit/settings/error'
import contracts from '@/services/config/api/contracts'

import type { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'
import { DomainContext } from '@/services/context/domain'
import cache from '@/services/storage/caching-decorator'
import storage from '@/services/business-unit/settings/storage'

export interface BusinessUnitRepositoryInterface {
  get(): Promise<BusinessUnit>
}

export const CACHE_NAMESPACE = 'BusinessUnitRepository:get'

export default class BusinessUnitRepository implements BusinessUnitRepositoryInterface {
  constructor(
    private readonly domainContext: DomainContext,
    private readonly endpointLocatorProvider: EndpointLocatorProvider,
  ) {}
  @cache<BusinessUnit>(storage, {
    namespace: CACHE_NAMESPACE,
  })
  async get(): Promise<BusinessUnit> {
    const locator = this.endpointLocatorProvider('GET_BUSINESS_UNIT_BY_DOMAIN')
    const request = client<BusinessUnit, BusinessUnitRequest, BusinessUnitApiError>(
      locator,
      contracts.GET_BUSINESS_UNIT_BY_DOMAIN,
      BusinessUnitApiError,
    )
    return await request({
      domain: this.domainContext.domain,
    } as BusinessUnitRequest)
  }
}
