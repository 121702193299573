<template>
  <svg viewBox="0 0 32 32">
    <path
      fill="#ffb229"
      style="fill: var(--color1, #ffb229)"
      d="M14.114 10.288c-0.683 1.386-2.004 2.346-3.533 2.569l-4.474 0.65 3.234 3.153c1.106 1.077 1.612 2.631 1.351 4.152l-0.764 4.456 4.001-2.103c1.367-0.719 3.001-0.719 4.366 0l4.002 2.104-0.765-4.461c-0.259-1.52 0.246-3.071 1.35-4.147l3.236-3.155-4.472-0.65c-1.527-0.222-2.849-1.181-3.532-2.566l-2-4.053-2 4.050zM15.003 0.69c0.209-0.422 0.64-0.69 1.111-0.69s0.902 0.268 1.111 0.69l3.984 8.074c0.18 0.365 0.529 0.619 0.933 0.677l8.91 1.294c0.467 0.068 0.854 0.395 1 0.843s0.024 0.941-0.313 1.27l-6.447 6.285c-0.292 0.284-0.425 0.695-0.357 1.096l1.522 8.875c0.079 0.465-0.111 0.935-0.494 1.212-0.382 0.276-0.886 0.313-1.305 0.095l-7.97-4.19c-0.36-0.19-0.792-0.19-1.153 0l-7.97 4.19c-0.417 0.219-0.923 0.183-1.305-0.094s-0.572-0.747-0.493-1.212l1.522-8.875c0.069-0.402-0.065-0.812-0.357-1.096l-6.447-6.285c-0.338-0.329-0.459-0.822-0.313-1.27s0.533-0.775 1-0.843l8.911-1.294c0.403-0.059 0.752-0.312 0.933-0.678l3.986-8.073z"
    ></path>
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
