import { match } from 'ts-pattern'
import { logLevels } from 'roarr/dist/src/constants'
import { event } from 'vue-gtag'
import config from '@/services/config'
import { datadogLogs } from '@datadog/browser-logs'

type LogObject = { context?: { logLevel?: number } }

export default function (object?: LogObject): (value: unknown, context?: unknown) => void {
  const logLevel = object?.context?.logLevel ?? logLevels.info

  return match<number>(logLevel)
    .with(logLevels.trace, () => console.trace)
    .with(logLevels.warn, () => console.warn)
    .with(logLevels.error, logLevels.fatal, () => {
      if (config.datadog.applicationId !== undefined && config.datadog.clientToken !== undefined) {
        try {
          datadogLogs.logger.info('Funnel custom event error', {
            error: object,
          })
        } catch (error) {
          console.log('Failed to send datadog logs', error)
        }
      }
      return console.error
    })
    .with(logLevels.debug, () => console.debug)
    .otherwise(() => {
      if (config?.isTrackingEnabled) {
        try {
          const { message } = object as {
            context?: string
            message?: string
          }
          if (message) {
            const parsedMessage = JSON.parse(message as string)
            const { isTracked, eventName } = parsedMessage as {
              isTracked?: boolean
              eventName: string
            }
            if (isTracked) {
              event(eventName ? eventName : 'GTM_EVENT', object)
            }
          }
        } catch {
          console.log(`Tracking disabled for ${object?.context}`)
        }
      }
      return console.log
    })
}
