import { computed, ref, Ref, unref } from 'vue'
import type { DealExcerpt } from '@/components/deals-list/services/deals/response/types'
import { OpenDatesListType } from '@/services/business-unit/open-dates/types'
import { ComputedRef } from '@vue/reactivity'
import { FilterOptionsDataType } from '@/components/deals-list/types'
import {
  FiltersInterface,
  ResourceFilterInterface,
  ThemeInterface,
  TranslatableFilterSectionInterface,
  VisitorClassificationInterface,
} from '@/services/business-unit/i18n/types'
import { I18nFacadeType } from '@/services/i18n'

export default function (i18nFacade: I18nFacadeType): {
  dealList: Ref<DealExcerpt[]>
  filterOptionsData: ComputedRef<FilterOptionsDataType>
  openDates: Ref<OpenDatesListType>
} {
  const dealList = ref<DealExcerpt[]>([])

  const filterOptionsData: ComputedRef<FilterOptionsDataType> = computed<FilterOptionsDataType>(
    () => {
      const {
        visitorClassifications = [],
        theme = {},
        resourceFilters = [],
        filters = {},
      } = (unref(i18nFacade.i18nBusinessUnitData)?.common ||
        {}) as TranslatableFilterSectionInterface

      return {
        visitorClassifications: (visitorClassifications || []).map(
          (item: VisitorClassificationInterface) => ({
            code: item.code,
            name: item.name,
            segments: item.segments.map((segment) => ({
              code: segment.code,
              name: segment.name,
              namePlural: segment.namePlural,
              description: segment.description,
            })),
          }),
        ),
        theme: Object.values(theme || {}).map((item: ThemeInterface) => ({
          key: item.slug,
          content: item.name,
          active: item.active,
        })),
        resourceFilters: resourceFilters.map((item: ResourceFilterInterface) => ({
          key: item.slug,
          content: item.name,
        })),
        filters: Object.entries(filters || {}).map(([, filter]: [string, FiltersInterface]) => ({
          name: filter.name,
          code: filter.slug,
          question: filter.question,
          options: Object.values(filter.rows || {}).map((row) => ({
            key: row.slug,
            content: row.name,
            active: row.active,
          })),
        })),
        visitDate: '',
      }
    },
  )

  const openDates = ref<OpenDatesListType>([])

  return { dealList, filterOptionsData, openDates }
}
