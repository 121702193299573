export default function (
  imageUrl: string,
  width?: number,
  height?: number,
): { src: string; srcSet: string } {
  if (width || height) {
    let srcSet: string[] = []

    for (let i = 1; i <= 4; i++) {
      const newDimensions = (width ? width * i : 'any') + 'x' + (height ? height * i : 'any')
      // eslint-disable-next-line prettier/prettier
      const factor = width ? width * i + 'w' : (height ? height * i + 'h' : '')
      const lastPart = imageUrl?.slice(Math.max(0, imageUrl.lastIndexOf('/') + 1))
      const lastPartWithDimensions = `${newDimensions}/${lastPart} ${factor}`
      srcSet = [...srcSet, imageUrl?.replace(lastPart, lastPartWithDimensions)]
    }
    const finalSrcSetString = srcSet.join(',')
    return { src: srcSet[0] ?? '', srcSet: finalSrcSetString }
  } else return { src: imageUrl, srcSet: '' }
}
