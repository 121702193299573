import { ref, Ref } from 'vue'

export interface UiStateType {
  isLoading: Ref<boolean>
  isError: Ref<boolean>
  error: Ref<Error | undefined>
  isFiltersSectionDisplayed: Ref<boolean>
  isScrolled: Ref<boolean>
  fullyLoadedPage: Ref<boolean>
}

export default function useUiState(): UiStateType {
  const isLoading = ref<boolean>(false)
  const isError = ref<boolean>(false)
  const error = ref<Error | undefined>()
  const isFiltersSectionDisplayed = ref<boolean>(false)
  const isScrolled = ref<boolean>(false)
  const fullyLoadedPage = ref<boolean>(false)

  return {
    isLoading,
    isError,
    error,
    isFiltersSectionDisplayed,
    isScrolled,
    fullyLoadedPage,
  }
}
