import type { NavigationFailure, Router } from 'vue-router'
import type { DealsRequestDataType } from './types'
import { getPartial } from '@/components/deals-list/services/deals/request/normalize'
import { RouteLocationNormalizedLoaded } from 'vue-router'
import { computed, Ref } from 'vue'

const equals = (filterAsString: string, filterAsStringToCompare: string): boolean =>
  (filterAsString === '{}' && filterAsStringToCompare === '') ||
  (filterAsString === '' && filterAsStringToCompare === '{}') ||
  filterAsString === filterAsStringToCompare

const getFilterAsString = (route: RouteLocationNormalizedLoaded): string => {
  const routeFilter = route.params.filter

  const [filterAsString]: string[] = Array.isArray(routeFilter) ? routeFilter : [routeFilter]

  return filterAsString || '{}'
}

const getCurrentFilterAsString = (router: Router): string => {
  return getFilterAsString(router.currentRoute.value)
}

interface RouteFilterBridgeType {
  getDealsRequestFromRoute: (route: RouteLocationNormalizedLoaded) => DealsRequestDataType
  navigateToFilter: (value: DealsRequestDataType) => Promise<void | NavigationFailure | undefined>
}

export class RouteFilterBridge implements RouteFilterBridgeType {
  constructor(private readonly router: Router) {}
  getDealsRequestFromRoute(route: RouteLocationNormalizedLoaded): DealsRequestDataType {
    const filterAsString = getFilterAsString(route)
    const filterObject = JSON.parse(filterAsString)

    return getPartial(filterObject) as DealsRequestDataType
  }

  get dealRequestFilters(): Ref<DealsRequestDataType> {
    return computed(() => this.getDealsRequestFromRoute(this.router.currentRoute.value))
  }

  async navigateToFilter(
    value: DealsRequestDataType,
  ): Promise<void | NavigationFailure | undefined> {
    const filter = JSON.stringify(getPartial(value))
    const currentFilterAsString = getCurrentFilterAsString(this.router)

    if (equals(filter, currentFilterAsString)) {
      return
    }

    return this.router.push({
      name: this.router.currentRoute.value.name || 'deals-list',
      params: {
        ...this.router.currentRoute.value.params,
        filter: [filter],
      },
    })
  }
}
