import { ref } from 'vue'
import { isEmpty } from 'ramda'
import { DealDetails } from '@/components/deal-details/services/deal/response/types'

export default function () {
  const dealDetails = getDealDetailsSession()
  const hasUpsells: boolean = dealDetails !== null ? dealDetails.hasUpsells : false
  const visitTime = ref<string>('')

  return { hasUpsells, visitTime }
}

// TODO: duplicate method in another hook, extract somewhere else
const getDealDetailsSession = (): DealDetails => {
  const dealDetails = sessionStorage.getItem('DEAL_DETAILS') as string
  return JSON.parse(dealDetails)
}
