export function trimString(inputString: string, limit: number): string {
  return inputString.length > limit ? inputString.slice(0, limit) + '&hellip;' : inputString
}

export function trimStringWholeWord(inputString: string, limit: number): string {
  if (inputString.length <= limit) {
    return inputString
  }

  const delimiter = ' '
  let trimmedString: string = inputString.slice(0, limit)
  trimmedString = trimmedString.slice(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(delimiter)),
  )

  return trimmedString + '...'
}

export default { trimString, trimStringWholeWord }
