import { computed, inject, reactive, Ref, ref, ShallowReactive } from 'vue'
import {
  CustomerData,
  OverviewData,
} from '@/components/deal-booking/services/shopping-cart/request/types'
import { useI18n } from 'vue-i18n'
import { ComputedRef } from '@vue/reactivity'
import useVuelidate, { Validation } from '@vuelidate/core'
import { required, email, sameAs } from '@vuelidate/validators'
import { I18nFacadeType } from '@/services/i18n'
import { I18N_FACADE } from '@/injection-keys'
import shoppingCartHook from '@/components/deal-booking/services/shopping-cart/hooks/shopping-cart-hook'

export default function (): {
  customerData: ShallowReactive<CustomerData>
  checkboxTermsPolicy: ComputedRef<{ key: string; content: string }[]>
  checkboxNewsLetter: ComputedRef<{ key: string; content: string }[]>
  acceptedTermsPolicy: Ref<boolean>
  overviewData: Ref<OverviewData>
  v$: Ref<Validation>
  isConceptFlow: ComputedRef<boolean>
} {
  // Data properties
  const { t } = useI18n()
  const i18nFacade = inject<I18nFacadeType>(I18N_FACADE) as I18nFacadeType

  const acceptedTermsPolicy = ref<boolean>(false)

  const customerData = reactive<CustomerData>({
    email: '',
    firstName: '',
    lastName: '',
    nickName: '',
    gender: '',
    birthDate: '',
    street: '',
    houseNumber: '',
    zipCode: '',
    city: '',
    countryCode: '',
    mobileNumber: '',
    companyName: '',
    companyEmail: '',
    companyAddress: '',
    companyZipcode: '',
    companyCity: '',
    companyCountryCode: '',
    companyPhonenumber: '',
    companyTaxNumber: '',
    companyCocNumber: '',
    customReference: '',
    newsletter: false,
    customerType: 'private',
    termsPolicy: false,
  })

  const rules = ref<any>({
    firstName: { required },
    lastName: { required },
    email: { required, email },
    mobileNumber: { required },
    companyName: computed(() => (customerData.customerType !== 'private' ? { required } : {})),
    termsPolicy: { required, sameAs: sameAs(true) },
  })

  const v$ = useVuelidate(rules.value, customerData)

  const { shoppingCartRef } = shoppingCartHook()
  const isConcept = ref(shoppingCartRef.value.booking?.isConcept || false)
  const isConceptFlowAllowed = ref(shoppingCartRef.value.isConceptFlowAllowed || false)

  // Computed properties
  const buName = computed(() =>
    i18nFacade.i18nBusinessUnitData ? i18nFacade.i18nBusinessUnitData.value?.common.name : '',
  )
  const buNoRefundPolicyUrl = computed(() =>
    i18nFacade.i18nBusinessUnitData
      ? i18nFacade.i18nBusinessUnitData.value?.common.settings.MerchantNoRefundPolicy
      : '',
  )
  const termsAndConditionsUrl = computed(() =>
    i18nFacade.i18nBusinessUnitData
      ? i18nFacade.i18nBusinessUnitData.value?.common.settings.EmailTermsAndConditionsUrl
      : '',
  )
  const merchantPrivacyPolicyUrl = computed(() =>
    i18nFacade.i18nBusinessUnitData
      ? i18nFacade.i18nBusinessUnitData.value?.common.settings.MerchantPrivacyPolicy
      : '',
  )
  const briqPrivacyPolicyUrl = computed(() =>
    i18nFacade.i18nBusinessUnitData
      ? i18nFacade.i18nBusinessUnitData.value?.common.settings.BriqPrivacyPolicy
      : '',
  )
  const isConceptFlow = computed(() => isConceptFlowAllowed.value && isConcept.value)

  const checkboxTermsPolicy = computed(() => [
    {
      key: 'termsPolicy',
      content: `
                    <span>${t('yourDetailsTermsAcceptText')}</span>
                    <a href='${
                      buNoRefundPolicyUrl.value
                    }' target='_blank' rel='noopener noreferrer'>${t(
        'yourDetailsNoRefundPolicy',
      )}</a><span>, </span>
                    <a href='${
                      termsAndConditionsUrl.value
                    }' target='_blank' rel='noopener noreferrer'>${t(
        'yourDetailsTerms',
      )}</a><span>, </span>
                    <a href='${
                      merchantPrivacyPolicyUrl.value ? merchantPrivacyPolicyUrl.value[0] : ''
                    }' target='_blank' rel='noopener noreferrer'>${t(
        'yourDetailsPrivacyPolicy',
      ).replace(':name', buName.value as string)}</a><span>, </span>
                    <span>${t('yourDetailsTermsAndText')} </span>
                    <a href='${
                      briqPrivacyPolicyUrl.value
                    }' target='_blank' rel='noopener noreferrer'>${t(
        'yourDetailsPrivacyPolicyBriq',
      )}</a><span>. *</span>
                    `,
    },
  ])

  const checkboxNewsLetter = computed(() => [
    {
      key: 'newsLetter',
      content: `
                  <span>${t('yourDetailsNewsletter').replace(
                    ':url',
                    merchantPrivacyPolicyUrl.value ? merchantPrivacyPolicyUrl.value[0] : '',
                  )}</span>
                `,
    },
  ])

  const overviewData = ref<OverviewData>({
    deals: [
      {
        name: 'test',
        total_visitors: 3,
        price_per_person: 5,
        total_price: 15,
      },
    ],
    upsells: [
      {
        name: 'upsell 1',
        total_visitors: 2,
        price_per_person: 3,
        total_price: 6,
      },
      {
        name: 'upsell 2',
        total_visitors: 4,
        price_per_person: 2,
        total_price: 8,
      },
    ],
    vouchers: [
      {
        name: 'Voucher 20%',
        amount: -5.8,
      },
      {
        name: 'Voucher 30%',
        amount: -8.9,
      },
    ],
    subtotal: 23.2,
    taxes: [
      {
        name: 'Tax 5%',
        amount: 1.16,
      },
      {
        name: 'Tax 10%',
        amount: 2.32,
      },
    ],
    total: 26.68,
    total_pp: 8.9,
    totalVisitors: 10,
  })

  return {
    customerData,
    checkboxTermsPolicy,
    checkboxNewsLetter,
    acceptedTermsPolicy,
    overviewData,
    v$,
    isConceptFlow,
  }
}
