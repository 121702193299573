import type { InjectionKey } from 'vue'
import type { DomainContext } from '@/services/context/domain'
import type { BusinessUnitContext } from '@/services/context/business-unit'
import type { ConfigType } from '@/services/config'
import type { Logger } from '@/services/logger'
import type { Router } from 'vue-router'
import type { BusinessUnitRepositoryInterface } from '@/services/business-unit/settings/repository'
import type { OpenDatesRepositoryInterface } from '@/services/business-unit/open-dates/repository'
import type I18nRepository from '@/services/business-unit/i18n/repository'
import type { I18n } from 'vue-i18n'
import type { I18nFacadeType } from '@/services/i18n'
import type { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'

export const DOMAIN_CONTEXT: InjectionKey<DomainContext> = Symbol()
export const BUSINESS_UNIT_CONTEXT: InjectionKey<BusinessUnitContext> = Symbol()
export const I18N_FACADE: InjectionKey<I18nFacadeType> = Symbol()
export const CONFIG: InjectionKey<ConfigType> = Symbol()
export const LOGGER: InjectionKey<Logger> = Symbol()

export const ROUTER: InjectionKey<Router> = Symbol()
export const BUSINESS_UNIT_REPOSITORY: InjectionKey<BusinessUnitRepositoryInterface> = Symbol()
export const OPEN_DATES_REPOSITORY: InjectionKey<OpenDatesRepositoryInterface> = Symbol()

export const I18N_REPOSITORY: InjectionKey<I18nRepository> = Symbol()
export const I18N: InjectionKey<I18n> = Symbol()

export const ENDPOINT_LOCATOR_PROVIDER: InjectionKey<EndpointLocatorProvider> = Symbol()
