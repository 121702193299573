<template>
  <svg viewBox="0 0 32 32">
    <path
      d="M31.975 25.23c0.068 0.519-0.090 0.971-0.474 1.355l-4.504 4.47c-0.203 0.226-0.468 0.418-0.796 0.576s-0.649 0.26-0.965 0.305c-0.023 0-0.090 0.006-0.203 0.017s-0.259 0.017-0.44 0.017c-0.429 0-1.123-0.073-2.083-0.22s-2.134-0.508-3.522-1.084c-1.389-0.576-2.963-1.439-4.724-2.591s-3.635-2.732-5.622-4.741c-1.58-1.558-2.89-3.048-3.928-4.47s-1.874-2.738-2.506-3.945c-0.632-1.208-1.106-2.303-1.422-3.285s-0.531-1.829-0.643-2.54c-0.113-0.711-0.158-1.27-0.135-1.676s0.034-0.632 0.034-0.677c0.045-0.316 0.147-0.638 0.305-0.965s0.35-0.593 0.576-0.796l4.504-4.504c0.316-0.316 0.677-0.474 1.084-0.474 0.293 0 0.553 0.085 0.779 0.254s0.418 0.378 0.576 0.627l3.624 6.875c0.203 0.361 0.26 0.756 0.169 1.185s-0.282 0.79-0.576 1.084l-1.659 1.659c-0.045 0.045-0.085 0.119-0.119 0.22s-0.051 0.186-0.051 0.254c0.090 0.474 0.293 1.016 0.61 1.626 0.271 0.542 0.689 1.202 1.253 1.981s1.366 1.676 2.404 2.692c1.016 1.039 1.919 1.846 2.709 2.422s1.451 0.999 1.981 1.27 0.937 0.435 1.219 0.491l0.423 0.085c0.045 0 0.119-0.017 0.22-0.051s0.175-0.073 0.22-0.119l1.93-1.964c0.407-0.361 0.881-0.542 1.422-0.542 0.384 0 0.689 0.068 0.914 0.203h0.034l6.536 3.861c0.474 0.294 0.756 0.666 0.847 1.118z"
    ></path>
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
