<template>
  <svg viewBox="0 0 32 32" class="readjusted-icons">
    <path
      d="M18.578 16l12.888 12.888c0.712 0.712 0.712 1.866 0 2.578s-1.866 0.712-2.578 0l-12.888-12.888-12.888 12.888c-0.712 0.712-1.866 0.712-2.578 0s-0.712-1.866 0-2.578l12.888-12.888-12.888-12.888c-0.712-0.712-0.712-1.866 0-2.578s1.866-0.712 2.578 0l12.888 12.888 12.888-12.888c0.712-0.712 1.866-0.712 2.578 0s0.712 1.866 0 2.578l-12.888 12.888z"
    ></path>
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
