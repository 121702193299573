<template>
  <svg viewBox="0 0 32 32">
    <path
      fill="#ffb229"
      style="fill: var(--color1, #ffb229)"
      d="M15.003 0.69c0.209-0.422 0.64-0.69 1.111-0.69s0.902 0.268 1.111 0.69l3.984 8.074c0.18 0.365 0.529 0.619 0.933 0.677l8.91 1.294c0.467 0.068 0.854 0.395 1 0.843s0.024 0.941-0.313 1.27l-6.447 6.285c-0.292 0.284-0.425 0.695-0.357 1.096l1.522 8.875c0.079 0.465-0.111 0.935-0.494 1.212-0.382 0.276-0.886 0.313-1.305 0.095l-7.97-4.19c-0.36-0.19-0.792-0.19-1.153 0l-7.97 4.19c-0.417 0.219-0.923 0.183-1.305-0.094s-0.572-0.747-0.493-1.212l1.522-8.875c0.069-0.402-0.065-0.812-0.357-1.096l-6.447-6.285c-0.338-0.329-0.459-0.822-0.313-1.27s0.533-0.775 1-0.843l8.911-1.294c0.403-0.059 0.752-0.312 0.933-0.678l3.986-8.073zM18.212 10.288l-2-4.050v16.412c1.098 0.155 1.826 0.326 2.184 0.515l4.001 2.103-0.764-4.456c-0.261-1.522 0.245-3.075 1.351-4.152l3.234-3.153-4.474-0.65c-1.529-0.223-2.851-1.183-3.533-2.569z"
    ></path>
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
