import { PathArguments } from '@/services/client/types'

export default function replacePathArguments(path: string, args: PathArguments = {}): string {
  return (
    Object.keys(args)
      // DESC sort by length to avoid replacement parts of long keys
      .sort((string1: string, string2: string): number => string2.length - string1.length)
      // eslint-disable-next-line unicorn/no-array-reduce
      .reduce((acc: string, key: string): string => acc.replace(`:${key}`, args[key]), path)
  )
}
