import config from '@/services/config'

// Typescript types
import {
  CustomerData,
  OverviewData,
} from '@/components/deal-booking/services/shopping-cart/request/types'
import type { DealExcerpt } from '@/components/deals-list/services/deals/response/types'

const sendEvent = (payload: any) => {
  if (config.isTrackingEnabled) {
    window && window.dataLayer?.push(payload)
  }
}

const analytics = {
  localeSwitch: (locale: string) =>
    sendEvent({
      event: 'locale-change',
      locale,
    }),
  viewDeal: (deal: DealExcerpt, currency: string) => {
    sendEvent({
      event: 'view_item',
      ecommerce: {
        currency,
        value: deal.prices.person?.price,
        items: [
          {
            item_id: deal.key,
            item_name: deal.displayName,
            price: deal.prices.person?.price,
            quantity: 1,
          },
        ],
      },
    })
  },
  selectDeal: (deal: DealExcerpt, currency: string) => {
    sendEvent({
      event: 'add_to_cart',
      ecommerce: {
        currency,
        value: deal.prices.person?.price,
        items: [
          {
            item_id: deal.key,
            item_name: deal.displayName,
            price: deal.prices.person?.price,
            quantity: 1,
          },
        ],
      },
    })
  },
  bookingConfirmed: (customerData: CustomerData, overviewData: OverviewData, bookingKey: string) =>
    sendEvent({
      event: 'purchase',
      // GA implementation for e-commerce is different: https://developers.google.com/analytics/devguides/collection/ga4/set-up-ecommerce
      // Own implementation:
      transactionId: bookingKey,
      email: customerData.email,
      transactionTotal: overviewData.total,
      transactionTax:
        overviewData.taxes?.length < 1
          ? 0
          : overviewData.taxes.reduce((acc, o) => acc + o.amount, 0),
      transactionProducts: overviewData.deals.map((deal) => {
        return {
          sku: deal.name,
          name: deal.name,
          category: deal.name,
          price: deal.price_per_person,
          quantity: deal.total_visitors,
        }
      }),
    }),
}

export default analytics
