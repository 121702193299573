import { ManagedApiError } from '@/services/client/error'
import { I18nErrorObject } from '@/services/business-unit/i18n/types'

export class I18nApiError extends ManagedApiError {
  constructor(
    protected readonly httpCode: number | undefined,
    public readonly errorObject: I18nErrorObject,
    message?: string | undefined,
  ) {
    super(httpCode, errorObject, message)
  }
}
