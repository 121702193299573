import { computed, ToRefs, unref } from 'vue'
import {
  DealsCustomFilter,
  DealsRequestDataType,
} from '@/components/deals-list/services/deals/request/types'
import { FilterOptionsDataType, FilterOptionType } from '@/components/deals-list/types'
import { ComputedRef } from '@vue/reactivity'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  customFilterOptions: ComputedRef<FilterOptionType[]>
  customFilterValue: ComputedRef<(filterCode: string) => string[]>
  updateCustomFilterValue: (event: string[], filterCode: string) => void
} {
  const customFilterOptions = computed(() => {
    return (
      unref(props.filterOptionsData)
        .filters.filter((value) => value.options.length > 0)
        .filter((value) => (value.options = value.options.filter((item) => item.active))) || []
    )
  })

  const customFilterValue = computed(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => (filterCode: string) => {
      const filters = unref(props.dealRequestFilters)?.customFilters
      return filters?.find((item: DealsCustomFilter) => item.property === filterCode)?.values || []
    },
  )

  function updateCustomFilterValue(event: string[], filterCode: string) {
    const dealRequestFilters = unref(props.dealRequestFilters)
    const newRequest = {
      ...dealRequestFilters,
      customFilters: [
        ...(dealRequestFilters?.customFilters || []).filter(
          (item: DealsCustomFilter) => item.property !== filterCode,
        ),
        {
          property: filterCode,
          values: event,
        },
      ],
    }

    emit('update', newRequest as DealsRequestDataType)
  }

  return {
    customFilterOptions,
    customFilterValue,
    updateCustomFilterValue,
  }
}
