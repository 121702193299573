import { ApiConfig, ApiEndpointLocator, EndpointKey } from '@/services/config/api/types'

export type EndpointLocatorProvider = (
  endpointKey: EndpointKey,
  pathParameter?: string,
) => ApiEndpointLocator

export default (config: ApiConfig): EndpointLocatorProvider =>
  (endpointKey: EndpointKey, pathParameter?: string): ApiEndpointLocator => {
    let pathLocator = config.endpoints[endpointKey]
    if (pathParameter) {
      pathLocator += '/' + pathParameter
    }
    return {
      schema: config.schema,
      host: config.host,
      port: config.port,
      path: pathLocator,
    } as ApiEndpointLocator
  }
