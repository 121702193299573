import { filter, isEmpty, pipe, reduce, map, find, propEq } from 'ramda'
import { SegmentType } from '@/components/booking-upsells/services/upsells/response/types'
import { VisitorClassification } from '@/components/deal-booking/utils/types'

export const checkIfSelectedVisitorsPresent = (visitorSegmentsData: SegmentType[]): boolean => {
  const hasSelectedVisitorsSegments = pipe(
    filter((s: SegmentType) => s.visitors > 0),
    isEmpty,
  )(visitorSegmentsData)

  return !hasSelectedVisitorsSegments
}

export const totalNumberOfSelectedVisitorsInMetadata = (
  visitorSegmentsData: VisitorClassification[],
): number => {
  const visitorArray =
    visitorSegmentsData && visitorSegmentsData.length > 0 ? visitorSegmentsData[0].segments : []
  return reduce((a, b) => a + b.visitors, 0, visitorArray)
}

export const mergeMetadataSegmentsWithVisitorSegments = (
  metadataVisitorSegmentsData: SegmentType[],
  visitorSegmentsData: SegmentType[],
): SegmentType[] => {
  return map((v: SegmentType) => {
    const mtv = find(propEq('code', v.code))(metadataVisitorSegmentsData) as SegmentType
    if (mtv) v.visitors = mtv.visitors
    return v
  })(visitorSegmentsData)
}
