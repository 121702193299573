import { I18nBusinessUnitData, I18nRequest } from '@/services/business-unit/i18n/types'
import client from '@/services/client'
import { I18nApiError } from '@/services/business-unit/i18n/error'
import contracts from '@/services/config/api/contracts'

import type { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'
import { DomainContext } from '@/services/context/domain'
import cache from '@/services/storage/caching-decorator'
import storage from '@/services/business-unit/i18n/storage'
import { CultureName } from '@/types/common'

export interface I18nRepositoryInterface {
  get(locale: CultureName): Promise<I18nBusinessUnitData>
}

export const CACHE_NAMESPACE = 'BusinessUnitRepository:get'

export default class I18nRepository implements I18nRepositoryInterface {
  constructor(
    private domainContext: DomainContext,
    private endpointLocatorProvider: EndpointLocatorProvider,
  ) {}
  @cache<I18nBusinessUnitData>(storage, {
    namespace: CACHE_NAMESPACE,
  })
  async get(locale: CultureName): Promise<I18nBusinessUnitData> {
    const locator = this.endpointLocatorProvider('GET_TRANSLATIONS_BY_DOMAIN')
    const request = client<I18nBusinessUnitData, I18nRequest, I18nApiError>(
      locator,
      contracts.GET_TRANSLATIONS_BY_DOMAIN,
      I18nApiError,
    )

    return await request({
      domain: this.domainContext.domain,
      locale,
    } as I18nRequest)
  }
}
