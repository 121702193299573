import { ManagedApiError } from '@/services/client/error'
import { UpsellsErrorResponseType } from '@/components/booking-upsells/services/upsells/response/types'

export class UpsellsError extends ManagedApiError {
  constructor(
    protected readonly httpCode: number | undefined,
    public readonly errorObject: UpsellsErrorResponseType,
    message?: string | undefined,
  ) {
    super(httpCode, errorObject, message)
  }
}
