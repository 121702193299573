import { pipe, match, toLower, map, join } from 'ramda'

export function currencyFormat(cultureName: string, currency: string, price: number): string {
  return Intl.NumberFormat(cultureName, {
    style: 'currency',
    currency: currency,
  }).format(price)
}

export function dateFormat(date: string): string {
  const [day, month, year] = date.split('-')

  return `${year}-${month}-${day}`
}

export function toKebab(string: string): string {
  return pipe(
    match(/[A-Z]{2,}(?=[A-Z][a-z]+\d*|\b)|[A-Z]?[a-z]+\d*|[A-Z]|\d+/g),
    map(toLower),
    join('-'),
  )(string)
}
