import { computed, ref, Ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import type {
  DealDetails,
  DealPrices,
} from '@/components/deal-details/services/deal/response/types'
import shoppingCartHook from '@/components/deal-booking/services/shopping-cart/hooks/shopping-cart-hook'

import { RouteLocationNormalizedLoaded } from 'vue-router'
import { I18nFacadeType } from '@/services/i18n'
const { shoppingCartRef } = shoppingCartHook()

import {
  DealDetailsRequestDataType,
  DealSegment,
  VisitorClassification,
} from '@/components/deal-details/services/deal/request/types'

export default function (
  i18nFacade: I18nFacadeType,
  route: RouteLocationNormalizedLoaded,
): {
  dealDetails: Ref<DealDetails | undefined>
  dealPrices: Ref<DealPrices | undefined>
  dealDetailsDataRequest: Ref<DealDetailsRequestDataType>
  dealDetailsFromSession: DealDetails
  goToDealDetailsAndCancelSelection: () => void
} {
  const queryParams = computed(() => route.query)
  const router = useRouter()

  const getSegmentData = () => {
    const date = (queryParams.value.date as string) ?? undefined
    let urlSegments = Object.keys(queryParams.value)
      .map((key) => {
        return { code: key, visitors: queryParams.value[key] }
      })
      .filter((item) => item?.code !== 'date')

    const { visitorClassifications = [] } = (i18nFacade?.i18nBusinessUnitData?.value?.common ??
      {}) as DealDetailsRequestDataType

    const buSegments = new Set(
      visitorClassifications
        .flatMap((item: VisitorClassification) => item.segments)
        .map((val: DealSegment) => val.code),
    )
    const visitorClassificationCode = visitorClassifications.map(
      (val: VisitorClassification) => val.code,
    )[0]

    urlSegments = urlSegments.filter((item) => buSegments.has(item.code) && item.visitors !== '0')

    return {
      visitorClassifications:
        urlSegments.length > 0
          ? [
              {
                code: visitorClassificationCode ?? '',
                segments: urlSegments ?? [],
              },
            ]
          : undefined,
      visitDate: date,
    } as DealDetailsRequestDataType
  }

  const dealDetailsDataRequest = computed<DealDetailsRequestDataType>({
    get: getSegmentData,
    set: (val) => val,
  })

  const dealDetails = ref<DealDetails>()
  const dealPrices = ref<DealPrices>()

  watch(
    () => queryParams.value,
    () => {
      dealDetailsDataRequest.value = getSegmentData()
    },
    { immediate: true },
  )

  watch(
    () => dealDetails.value,
    () => {
      if (dealDetails.value) saveDealDetailsSession(dealDetails.value)
    },
    { immediate: true },
  )

  const dealDetailsFromSession = getDealDetailsSession()

  const goToDealDetailsAndCancelSelection = (): void => {
    if (shoppingCartRef.value && shoppingCartRef.value.booking) {
      const lastDealSlug = shoppingCartRef.value.booking.deals
        ? dealDetailsFromSession.slug
        : undefined
      shoppingCartRef.value = { booking: {}, summary: {} }
      router.push({ name: 'deal-details-view', params: { slug: lastDealSlug } })
    } else {
      router.push({ name: 'deals-list' })
    }
  }

  return {
    dealDetails,
    dealPrices,
    dealDetailsDataRequest,
    dealDetailsFromSession,
    goToDealDetailsAndCancelSelection,
  }
}

const saveDealDetailsSession = (dealDetails: DealDetails): void => {
  sessionStorage.setItem('DEAL_DETAILS', JSON.stringify(dealDetails))
}

const getDealDetailsSession = (): DealDetails => {
  const dealDetails = sessionStorage.getItem('DEAL_DETAILS') as string
  return JSON.parse(dealDetails)
}
