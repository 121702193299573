import { computed, ToRefs, unref } from 'vue'
import { FilterOptionsDataType, ResourceFiltersOptionType } from '@/components/deals-list/types'
import { DealsRequestDataType } from '@/components/deals-list/services/deals/request/types'
import { ComputedRef } from '@vue/reactivity'
import { map } from 'ramda'

export default function (
  props: ToRefs<
    Readonly<{
      filterOptionsData: FilterOptionsDataType
      dealRequestFilters: DealsRequestDataType
    }>
  >,
  emit: (e: 'update', dealRequestFilters: DealsRequestDataType) => void,
): {
  resourceFilters: ComputedRef<{ key: string; content: string }[]>
  removeResourceFilter: (key: string) => void
} {
  const resourceFilters = computed(() => {
    const resourceFilters = unref(props.dealRequestFilters).resourceFilters || []
    const resourceFiltersInLowerCase = map((f: string) => f.toLowerCase())(resourceFilters)

    return unref(props.filterOptionsData).resourceFilters.filter(
      (item: ResourceFiltersOptionType) => {
        return resourceFiltersInLowerCase.includes(item.key)
      },
    )
  })

  function removeResourceFilter(key: string) {
    const requestFilters = unref(props.dealRequestFilters)
    const newRequest = {
      ...requestFilters,
      resourceFilters: requestFilters?.resourceFilters?.filter(
        (item: string) => item.toLowerCase() !== key,
      ),
    }

    emit('update', newRequest as DealsRequestDataType)
  }

  return {
    resourceFilters,
    removeResourceFilter,
  }
}
