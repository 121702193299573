import { DealSegment } from '@/components/booking-upsells/services/upsells/request/types'
import { filter, clone, map } from 'ramda'

interface VisitorClassification {
  code: string
  name: string
  segments: DealSegment[]
}

export const getVisitorsClassificationsWithVisitors = (
  visitorClassification: VisitorClassification[],
): VisitorClassification[] => {
  return map((vc: VisitorClassification) => {
    vc.segments = filter((s: DealSegment) => {
      return s.visitors > 0
    })(vc.segments)
    return vc
  })(clone(visitorClassification))
}
