import {
  DealsExcerptsRequestType,
  DealsRequestDataType,
} from '@/components/deals-list/services/deals/request/types'
import { DealExcerptsResponse } from '@/components/deals-list/services/deals/response/types'
import client from '@/services/client'

import contracts from '@/services/config/api/contracts'

import type { EndpointLocatorProvider } from '@/services/client/api-endpoint-locator-provider'
import { DomainContext } from '@/services/context/domain'
import { CultureName } from '@/types/common'
import { DealListError } from '@/components/deals-list/services/deals/response/errors'
import { dateFormat } from '@/services/string-helpers/formatters'

export interface DealExcerptRepositoryInterface {
  get(request: DealsRequestDataType, locale: CultureName): Promise<DealExcerptsResponse>
}

export default class DealExcerptRepository implements DealExcerptRepositoryInterface {
  constructor(
    private domainContext: DomainContext,
    private endpointLocatorProvider: EndpointLocatorProvider,
  ) {}

  async get(
    requestData: Partial<DealsRequestDataType>,
    locale: CultureName,
  ): Promise<DealExcerptsResponse> {
    const locator = this.endpointLocatorProvider('GET_DEAL_EXCERPTS_LIST')
    const request = client<DealExcerptsResponse, DealsExcerptsRequestType, DealListError>(
      locator,
      contracts.GET_DEAL_EXCERPTS_LIST,
      DealListError,
    )

    const normalizedRequestData = {
      ...requestData,
      ...(requestData.visitDate !== undefined
        ? { visitDate: dateFormat(requestData.visitDate) }
        : {}),
    } as Partial<DealsRequestDataType>

    return await request({
      requestData: normalizedRequestData,
      domain: this.domainContext.domain,
      locale,
    } as DealsExcerptsRequestType)
  }
}
