<template>
  <svg viewBox="0 0 32 32">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.486 0 0 4.486 0 10C0 15.514 4.486 20 10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 0 10 0ZM9.99965 18.3262C5.40834 18.3262 1.67273 14.591 1.67273 9.99944C1.67273 5.40792 5.40834 1.67273 9.99965 1.67273C14.591 1.67273 18.3266 5.40792 18.3266 9.99944C18.3266 14.591 14.591 18.3262 9.99965 18.3262ZM8.30752 12.6923C8.67005 13.055 9.25857 13.055 9.62128 12.6923L13.728 8.58561C13.9021 8.41144 14 8.17512 14 7.92882C14 7.68252 13.9021 7.4462 13.728 7.27203C13.3653 6.90932 12.7767 6.90932 12.4142 7.27203L9.13043 10.5558C9.03871 10.6472 8.89009 10.6472 8.79854 10.5558L7.58579 9.34323C7.22308 8.98053 6.63456 8.98053 6.27203 9.34323C5.90932 9.70576 5.90932 10.2943 6.27203 10.6568L8.30752 12.6923Z"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped></style>
