import { computed, ref, WritableComputedRef } from 'vue'
import { Upsell } from '@/components/booking-upsells/services/upsells/response/types'
import { UpsellFetchStatuses } from '@/components/booking-upsells/general-types/types'
import { forEach } from 'ramda'

export default function (): {
  dealUpsells: WritableComputedRef<any | []>
  upsellPricesFetchStatusesRef: WritableComputedRef<any | undefined>
  upsellPricesRef: WritableComputedRef<any | undefined>
  setDefaultFetchStatusesOfUpsells: () => void
} {
  // TODO: Loaded by the api
  const upsellPricesFetchStatuses = ref()
  const upsellPrices = ref({})
  const apiUpsells = ref<Upsell[]>([])
  const dealUpsells = computed<Upsell[]>({
    get(): Upsell[] {
      return apiUpsells.value
    },
    set(requestData: Upsell[]): void {
      apiUpsells.value = requestData
    },
  })

  const upsellPricesFetchStatusesRef = computed<UpsellFetchStatuses>({
    get(): UpsellFetchStatuses {
      return upsellPricesFetchStatuses.value
    },
    set(requestData: UpsellFetchStatuses): void {
      upsellPricesFetchStatuses.value = requestData
    },
  })

  const upsellPricesRef = computed<any>({
    get(): any {
      const upsellPricesFromSession = sessionStorage.getItem('UPSELL_PRICES')
      return upsellPricesFromSession !== null
        ? JSON.parse(upsellPricesFromSession as string)
        : upsellPrices.value
    },
    set(requestData: any): void {
      upsellPrices.value = requestData

      saveUpsellPricesInSession(upsellPrices.value)
    },
  })

  // Methods
  const setDefaultFetchStatusesOfUpsells = (): void => {
    const upsellFetchStatuses: UpsellFetchStatuses = {}
    forEach((u: Upsell) => {
      upsellFetchStatuses[u.key] = {
        isLoading: false,
        hasError: false,
      }
    })(dealUpsells.value)
    upsellPricesFetchStatusesRef.value = upsellFetchStatuses
  }

  return {
    dealUpsells,
    upsellPricesFetchStatusesRef,
    upsellPricesRef,
    setDefaultFetchStatusesOfUpsells,
  }
}

// Methods
const saveUpsellPricesInSession = (upsellPrices: any): void => {
  sessionStorage.setItem('UPSELL_PRICES', JSON.stringify(upsellPrices))
}
